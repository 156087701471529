import React, { Component } from 'react';
import { BrowserRouter, Routes, Route, Outlet } from "react-router-dom";

// Elements
import BackToTop from './elements/back-top';
import PageScrollTop from './elements/page-scroll-top';

// Layout
import Header from "./layout/header";
import Footer from "./layout/footer";

// All Pages Router
import Index from './pages/index';
import AboutUs from './pages/about-us';
import Team from './pages/team';
import Services from './pages/services';
import ServiceDetail from './pages/service-detail';
import FormLogin from './pages/form-login';
import FormRegister from './pages/form-register';
import FormForgetPassword from './pages/form-forget-password';
import Faq from './pages/faq';
import ContactUs from './pages/contact-us';
import Booking from './pages/booking';
import BlogGrid from './pages/blog-grid';
import BlogDetails from './pages/blog-details';
import Error from './pages/error-404';
import CommingSoon from './pages/comming-soon';
import BookingStatus from './pages/bookingstatus';

//Tests
import BioMicroscopyDetail from './pages/tests/biomicroscopy';
import CornealTopographyDetails from './pages/tests/cornealtopography';
import DryEyeDetails from './pages/tests/dryeyetest';
import FundoscopyDetail from './pages/tests/fundoscopy';
import KeratometryDetail from './pages/tests/keratometry';
import MeibomianGlandAssesmentDetail from './pages/tests/meibomianglandassesment';
import OcularBiometryDetail from './pages/tests/ocularbiometry';
import OpticalCoherenceTomographyDetail from './pages/tests/opticalcoherencetomography';
import PachymetryDetail from './pages/tests/pachymetry';
import PerimetryDetail from './pages/tests/perimetry';
import RefractionDetail from './pages/tests/refraction';
import SensoryMotorTestDetail from './pages/tests/sensorymotor';
import SynoptophoreDetail from './pages/tests/synoptophore';
import TonometryDetail from './pages/tests/tonometry';

//Services
import StandardEyeExam from './pages/services/standardeyeexam';
import PremiumExam from './pages/services/premiumeyeexam';
import PediatricExam from './pages/services/pediatriceyeexam';
import ExecutiveExam from './pages/services/executiveeyeexam';
import ExecutivePlusExam from './pages/services/executiveeyeplusexam';
import FollowupEyeExam from './pages/services/followupeyeexam';
import CorporateEyeExam from './pages/services/corporateeyeexam';

import Biometry from './pages/services/biometry';
import VisualPA from './pages/services/visualperformanceassessment';
import FundusAirdoc from './pages/services/funduswithairdoc';
import FundusKanghua from './pages/services/funduswithkanghua';
import ForeignBodiesRemoval from './pages/services/foreignbodiesremoval';
import IshiharaTest from './pages/services/ishiharatest';
import IshiharaFanswort from './pages/services/ishiharafanswort';
import Perimetry from './pages/services/perimetry';
import IOPAssessment from './pages/services/iopassessment';
import Orthokeratology from './pages/services/orthokeratology';
import OrthokeratologyLens from './pages/services/orthokeratologylens';
import CycloplegicRefraction from './pages/services/cycloplegicref';
import Topography from './pages/services/topography';
import VisionTherapy from './pages/services/visiontherapy';

//Conditions
import AccommodativeVergenceDysfunction from './pages/visionconditions/avdysfunction';
import Amblyopia from './pages/visionconditions/amblyopia';
import Astigmatism from './pages/visionconditions/astigmatism';

class Markup extends Component{
	render(){
		return(
			<>	
			
				{/* {<BrowserRouter basename={'/react/'}> */}
				<BrowserRouter>
					
					<Routes>
						
						<Route element={<ThemeLayout />}>
							<Route path='/' element={<Index />} />
							<Route path='/about-us' element={<AboutUs />} />
							<Route path='/team' element={<Team />} />
							<Route path='/services' element={<Services />} />
							<Route path='/service-detail' element={<ServiceDetail />} />
							<Route path='/faq' element={<Faq />} />
							<Route path='/contact-us' element={<ContactUs />} />
							<Route path='/booking' element={<Booking />} />
							<Route path='/blog-grid' element={<BlogGrid />} />
							<Route path='/blog-details' element={<BlogDetails />} />
							<Route path='/commingsoon' element={<CommingSoon />} />
							<Route path='/bookingstatus/:bookingid' element={<BookingStatus />} />

							{/* TESTS */}
							<Route path='/biomicroscopy-tests' element={<BioMicroscopyDetail />} />
							<Route path='/cornealtopography-tests' element={<CornealTopographyDetails />} />
							<Route path='/dryeye-tests' element={<DryEyeDetails />} />
							<Route path='/fundoscopy-tests' element={<FundoscopyDetail />} />
							<Route path='/keratometry-tests' element={<KeratometryDetail />} />
							<Route path='/meibomianglandassessment-tests' element={<MeibomianGlandAssesmentDetail />} />
							<Route path='/ocularbiometry-tests' element={<OcularBiometryDetail />} />
							<Route path='/opticalcoherencetomography-tests' element={<OpticalCoherenceTomographyDetail />} />
							<Route path='/pachymetry-tests' element={<PachymetryDetail />} />
							<Route path='/perimetry-tests' element={<PerimetryDetail />} />
							<Route path='/refraction-tests' element={<RefractionDetail />} />
							<Route path='/sensorymotor-tests' element={<SensoryMotorTestDetail />} />
							<Route path='/synoptophore-tests' element={<SynoptophoreDetail />} />
							<Route path='/tonometry-tests' element={<TonometryDetail />} />
							{/* Services */}
							<Route path='/standardeyeexam' element={<StandardEyeExam />} />
							<Route path='/premiumeyeexam' element={<PremiumExam />} />
							<Route path='/pediatriceyeexam' element={<PediatricExam />} />
							<Route path='/executiveeyeexam' element={<ExecutiveExam />} />
							<Route path='/executiveeyeplusexam' element={<ExecutivePlusExam />} />
							<Route path='/followupeyeexam' element={<FollowupEyeExam />} />
							<Route path='/corporateeyeexam' element={<CorporateEyeExam />} />

							<Route path='/biometry' element={<Biometry />} />
							<Route path='/visualpa' element={<VisualPA />} />
							<Route path='/fundusairdoc' element={<FundusAirdoc />} />
							<Route path='/funduskanghua' element={<FundusKanghua />} />
							<Route path='/foreignbdr' element={<ForeignBodiesRemoval />} />
							<Route path='/ishiharatest' element={<IshiharaTest />} />
							<Route path='/ishiharafanswort' element={<IshiharaFanswort />} />
							<Route path='/perimetry' element={<Perimetry />} />
							<Route path='/iopassessment' element={<IOPAssessment />} />
							<Route path='/orthokeratology' element={<Orthokeratology />} />
							<Route path='/orthokeratologylens' element={<OrthokeratologyLens />} />
							<Route path='/cycloplegicrefraction' element={<CycloplegicRefraction />} />
							<Route path='/topography' element={<Topography />} />
							<Route path='/visiontherapy' element={<VisionTherapy />} />

							{/* Conditions */}
							<Route path='/accommodativevergencedysfunction' element={<AccommodativeVergenceDysfunction />} />
							<Route path='/amblyopia' element={<Amblyopia />} />
							<Route path='/astigmatism' element={<Astigmatism />} />

							<Route path="*" element={<Error />} />
						</Route>
						
						<Route path="/form-login" element={<FormLogin />} />
						<Route path="/form-register" element={<FormRegister />} />
						<Route path='/form-forget-password' element={<FormForgetPassword />} />
						
					</Routes>
					
					<PageScrollTop />
					
				</BrowserRouter>
				
				<BackToTop />
				
			</>
		);
	}
}
function ThemeLayout(){
	return(
		<>
			<Header />
			<Outlet />
			<Footer />
		</>
	);
}
export default Markup;