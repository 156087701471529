import React, { Component } from 'react';

class appointmentForm extends Component{
	
	render(){
		return(
			<>
				
				<div className="appointment-form">
					
					<h3 className="title">Book Appointment</h3>
					<p>Comming Soon</p>
					{ /*<Iframe iframe='<iframe width="100%" height="850" scrolling="no" frameborder="no" allow="autoplay" src="https://calendly.com/kelvin-nakpil/checkup"></iframe>' />*/}
				</div>
			
			</>
		);
	}
	
}


function Iframe(props) {
	return (
	  <div
		dangerouslySetInnerHTML={{ __html: props.iframe ? props.iframe : "" }}
	  />
	);
  }

export default appointmentForm;