import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import Slider from "react-slick";

// Import Images
import testShape from "../../images/testimonials/shape.png"
import testPic1 from "../../images/testimonials/doc1.jpg"
import testPic2 from "../../images/testimonials/doc2.jpg"
import testPic3 from "../../images/testimonials/doc3.jpg"
import testPic4 from "../../images/testimonials/doc4.jpg"
import testPic5 from "../../images/testimonials/doc5.jpg"
import testPic6 from "../../images/testimonials/doc6.jpg"
import plusOrange from "../../images/shap/plus-orange.png"
import squareBlue from "../../images/shap/square-blue.png"
import circleDots from "../../images/shap/circle-dots.png"
import circleOrange2 from "../../images/shap/circle-orange-2.png"

class testimonialSection extends Component{
	
	render(){
			
		const settings = {
			dots: false,
			infinite: true,
			speed: 1000,
			slidesToShow: 1,
			slidesToScroll: 1,
		};
		
		return(
			<>
					
				
				<section className="section-area section-sp3 testimonial-wraper">
					<div className="container">
						<div className="heading-bx text-center">
							<h6 className="title-ext text-primary">Testimonial</h6>
							<h2 className="title m-b0">See What Are The Patients <br/>Saying About us</h2>
						</div>
						<div className="row align-items-center">
							
							<div className="col-lg-12">
								<Slider {...settings} className="testimonial-slide">
									<div className="slider-item">
										<div className="testimonial-bx">
											<div className="testimonial-content">
												<p>I recently had an eye examination at Nakpil Eye Clinic & Vision Therapy Center, and I’m thrilled with the results. The optometrist was thorough, explaining each step of the process. They detected a minor refractive error, and now, with my new glasses, the world is sharper and more vibrant. Thank you, Nakpil Eye Clinic & Vision Therapy Center, for caring about my vision!</p>
											</div>
											<div className="client-info">
												<h5 className="name">Kelvin</h5>
												<p>patient</p>
											</div>
											<div className="quote-icon">
												<i className="fas fa-quote-left"></i>
											</div>
										</div>
									</div>
									<div className="slider-item">
										<div className="testimonial-bx">
											<div className="testimonial-content">
												<p>I visited Nakpil Eye Clinic & Vision Therapy Center for an eye examination, and I’m delighted with the experience. The optometrist was patient, explaining each test thoroughly. They identified a minor issue with my vision, and now, with my new prescription, the world is clearer. Thank you, Nakpil Eye Clinic & Vision Therapy Center, for improving my quality of life!</p>
											</div>
											<div className="client-info">
												<h5 className="name">Riza</h5>
												<p>patient</p>
											</div>
											<div className="quote-icon">
												<i className="fas fa-quote-left"></i>
											</div>
										</div>
									</div>
									<div className="slider-item">
										<div className="testimonial-bx">
											<div className="testimonial-content">
												<p>I had my eye exam at Nakpil Eye Clinic & Vision Therapy Center, and it was a game-changer. The optometrist was thorough, explaining everything step by step. They caught a minor issue with my vision, and now, with my new glasses, I see the world in high definition. Thank you, Nakpil Eye Clinic & Vision Therapy Center, for brightening my days!</p>
											</div>
											<div className="client-info">
												<h5 className="name">Lea</h5>
												<p>patient</p>
											</div>
											<div className="quote-icon">
												<i className="fas fa-quote-left"></i>
											</div>
										</div>
									</div>
									<div className="slider-item">
										<div className="testimonial-bx">
											<div className="testimonial-content">
												<p>I visited Nakpil Eye Clinic & Vision Therapy Center for my eye exam, and it exceeded my expectations. The optometrist was patient and thorough, explaining each test. They caught a minor issue with my vision, and now, with my new prescription, the world is clearer and more vibrant. Thank you, Nakpil Eye Clinic & Vision Therapy Center, for caring about my eyes!</p>
											</div>
											<div className="client-info">
												<h5 className="name">Claire</h5>
												<p>patient</p>
											</div>
											<div className="quote-icon">
												<i className="fas fa-quote-left"></i>
											</div>
										</div>
									</div>
									<div className="slider-item">
										<div className="testimonial-bx">
											<div className="testimonial-content">
												<p>“As an executive with a busy schedule, I needed an eye examination that was efficient and thorough. Nakpil Eye Clinic & Vision Therapy Center delivered exactly that. The optometrist conducted a comprehensive assessment, catching a minor issue with my vision. With my new prescription, I now lead meetings and presentations with crystal-clear clarity. Thank you, Nakpil Eye Clinic & Vision Therapy Center, for elevating my professional vision!</p>
											</div>
											<div className="client-info">
												<h5 className="name">Sir Reggie</h5>
												<p>patient</p>
											</div>
											<div className="quote-icon">
												<i className="fas fa-quote-left"></i>
											</div>
										</div>
									</div>
								</Slider>
							</div>	 
						</div>	 
					</div>
					<img className="pt-img1 animate1" src={plusOrange} alt=""/>
					<img className="pt-img2 animate2" src={squareBlue} alt=""/>
					<img className="pt-img3 animate3" src={circleDots} alt=""/>
					<img className="pt-img4 animate4" src={circleOrange2} alt=""/>
				</section>
				
			</>
		);
	}
}

export default testimonialSection;