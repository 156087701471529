import React, { Component, useState, useEffect } from 'react';
import { Link, useNavigate, useLocation } from "react-router-dom";
import ReCAPTCHA from 'react-google-recaptcha';
import BookingAPIService from '../../api/BookingService';
import { Formik ,Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';


const BookingForm = () => {

	let cur_date = new Date();
	cur_date.setDate(cur_date.getDate()+2);
	let st_mid_date = cur_date.toLocaleDateString("sv-SE");

	const navigate = useNavigate();
	const location = useLocation();

	const [selectedDate,setSelectedDate] = useState(st_mid_date);
	const [selectedTime,setSelectedTime] = useState("");
	const [selectedBranch,setSelectedBranch] = useState("");
	const [requestMessage,setRequestMessage] = useState("");
	const [isBookDisabled,setIsBookDisabled] = useState(true);
	const [captchaSRC,setcaptchaSRC] = useState();
	const [captCode,setCaptCode] = useState();

	const apiService = new BookingAPIService();


	const onSubmit = async (values, { setErrors, setStatus, setSubmitting ,resetForm }) => {     
        setStatus({ success: false });
        setSubmitting(false);           
		console.log("Submiting!");     

        try {  
            //console.log(values);
            setSubmitting(true);  
			const resp = await apiService.submitBooking(values);
			
			console.log(resp?.status)
			if(resp?.status >= 200){
				console.log("Booked");
				navigate("/bookingstatus/"+resp?.data.id,{state : {from : location}});
				
			}

        } catch (err) {
            setStatus({ success: false });
            setErrors({ submit: err.message });                        
            setSubmitting(false);
        }
    };

	const onChange = (e) => {
		setIsBookDisabled(false);
	}

	const onExpired = (e) =>{
		setIsBookDisabled(true);
	}

	const onCaptchaLoad = () =>{
		
	}

	let num = Math.random().toString().substr(2, 6);
    if (num.length < 6) num = `${parseInt(num) + 100000}`;

	function generateCaptcha(code, width = 100, height = 30, font = '30px Arial') {
		const canvas = document.createElement('canvas');
		canvas.width = width;
		canvas.height = height;
		const ctx = canvas.getContext('2d');
		ctx.font = font;
		ctx.textAlign = 'center';
		ctx.fillStyle = "#ffffff";
		ctx.textBaseline = "middle";
		ctx.textAlign = "center";
		ctx.fillText(code, canvas.width/2, canvas.height/2);
	  
		const dataURL = canvas.toDataURL('image/png');  
		const base64Image = new Image(width, height);
		base64Image.src= dataURL;
		return dataURL;
		//parent.innerHTML = '';
		//parent.appendChild(base64Image);

	}


	useEffect(()=>{
		setCaptCode(num);
		setcaptchaSRC(generateCaptcha(num));
	},[]);

	const initValues = {
		branch : "",
		patientname : "",
		email : "",
		mobile : "",
		remarks : "",
		bookingdate : "",
		bookingtime : "",
	}

	const validationScheme = Yup.object().shape({
        branch: Yup.mixed().required('Branch is required!').oneOf(["Dasmariñas","Alabang Festival Mall"],"Please select a branch!"),
        bookingdate: Yup.date().required('Appointment date is required!').test('Booking Date','Booking date is fully booked!', 
        async function(value){
                try{
					console.log(st_mid_date);
					console.log(value);

					if(value !== null && value !== '' && value !== undefined && value >= cur_date){	
						const resp = await apiService.checkDate(selectedDate,selectedTime,selectedBranch);
						if(resp?.status >= 200){
							return parseInt(resp?.data) <= 40;
						}
						else{
							return false;
						}
					} 
                }catch(err){
					console.log("err");
                    return false;
                }                
            }        
        ),
        bookingtime: Yup.mixed().required('Appointment time is required!').oneOf(["09:00","10:00","11:00","12:00","13:00","14:00","15:00","16:00","17:00","18:00","19:00"],"Please select appointment time!").test('Booking Time','Time period has been fully booked!', 
        async function(value){
                try{
					if(value !== null && value !== '' && value !== undefined){	
						const resp = await apiService.checkTime(selectedDate,selectedTime,selectedBranch);
						if(resp?.status >= 200){
							return parseInt(resp?.data) < 4;
						}
						else{
							return false;
						}
					}
                }catch(err){
                    return false;
                }                
            }        
        ),
		patientname : Yup.string().min(1).max(120).required('Fullname is required!'),
		email : Yup.string().min(1).max(120).email('Must be a valid email').required('Email is required!'),
		mobile : Yup.string().min(1).max(120).required('Mobile number is required!').matches('^(639)\\d{9}$','Invalid Mobile number format (639173334444)'),        
    })

	

	return (
		<>				
			<div className="appointment-form">
				
				<h3 className="title">Book Appointment</h3>
				<p>Please fill in the details correctly</p>
				{ /*<Iframe iframe='<iframe width="100%" height="850" scrolling="no" frameborder="no" allow="autoplay" src="https://calendly.com/kelvin-nakpil/checkup"></iframe>' />*/}
				<section className="">
					<div className="container">
						<div className="row">
							<div className="col-lg-3 col-sm-12 mb-30"></div>
							<div className="col-lg-6 col-sm-12 mb-30">
							<Formik initialValues={initValues} validationSchema={validationScheme} onSubmit={onSubmit} validateOnChange={false} validateOnBlur={true}>
        						{({ errors, handleBlur, handleChange, handleSubmit, isSubmitting, touched, values , setFieldValue }) => (
								<form className="form-wraper contact-form ajax-form" onSubmit={handleSubmit }>
									
									<div className="row">
										
										<div className="form-group col-md-12">
											<select className={ touched.branch && errors.branch ? "form-select form-control invalid" : "form-select form-control"}
												name="branch"
												onChange={(e)=>{
													setFieldValue("branch",e.target.value);
													setFieldValue('bookingdate',"");
													setFieldValue('bookingtime',"");

													setSelectedBranch(e.target.value);
													setSelectedDate("");
													setSelectedTime("");
												}}
												onBlur={handleBlur}
												value={values.branch}
											>
												<option defaultValue="">Selecty Branch</option>
												<option defaultValue="Dasmariñas">Dasmariñas</option>
												<option defaultValue="Alabang Festival Mall">Alabang Festival Mall</option>
											</select>
											{touched.branch && errors.branch && (
												<ErrorMessage name="branch" component="div" className='error-div'/>												
											)}
										</div>
										<div className="form-group col-md-12">											
											<Field className={ touched.bookingdate && errors.bookingdate ? "form-control invalid" : "form-control" }
												type="date" 
												name="bookingdate" 
												placeholder="Select Appointment Date"
												onChange={(e)=>{
													setFieldValue('bookingdate',e.target.value);
													setFieldValue('bookingtime',"");
													setSelectedDate(e.target.value);
													setSelectedTime("");
												}}
												onBlur={handleBlur}
												min={st_mid_date}
												value={values.bookingdate}			
											/>
											{touched.bookingdate && errors.bookingdate && (
												<ErrorMessage name="bookingdate" component="div" className='error-div'/>												
											)}
										</div>										
										<div className="form-group col-md-12">
											<select className={ touched.bookingtime && errors.bookingtime ? "form-select form-control invalid" : "form-select form-control"}
												name="bookingtime"
												onChange={(e)=>{
													setFieldValue('bookingtime',e.target.value);
													setSelectedTime(e.target.value);
												}}
												onBlur={handleBlur}
												value={values.bookingtime}
												>
												<option value="" defaultValue="">Select Time</option>
												<option value="09:00" defaultValue="09:00">09:00 AM</option>
												<option value="10:00" defaultValue="10:00">10:00 AM</option>
												<option value="11:00" defaultValue="11:00">11:00 AM</option>
												<option value="12:00" defaultValue="12:00">12:00 NN</option>
												<option value="13:00" defaultValue="13:00">01:00 PM</option>
												<option value="14:00" defaultValue="14:00">02:00 PM</option>
												<option value="15:00" defaultValue="15:00">03:00 PM</option>
												<option value="16:00" defaultValue="16:00">04:00 PM</option>
												<option value="17:00" defaultValue="17:00">05:00 PM</option>
												<option value="18:00" defaultValue="18:00">06:00 PM</option>
												<option value="19:00" defaultValue="19:00">07:00 PM</option>
											</select>
											{touched.bookingtime && errors.bookingtime && (
												<ErrorMessage name="bookingtime" component="div" className='error-div'/>												
											)}
										</div>
										
										<div className="ajax-message mt-30 text-danger">{requestMessage}</div>

										<hr className="my-30"/>
										<p className="my-30">Patient Information</p>
										<div className="form-group col-md-12">
											<Field className={ touched.patientname && errors.patientname ? "form-control invalid" : "form-control" }
												type="text" 
												name="patientname" 
												placeholder="Your Name"
												onChange={handleChange}
												onBlur={handleBlur}
												value={values.patientname}			
											/>
											{touched.patientname && errors.patientname && (
												<ErrorMessage name="patientname" component="div" className='error-div'/>												
											)}
										</div>
										<div className="form-group col-md-12">
											<Field className={ touched.email && errors.email ? "form-control invalid" : "form-control" }
												type="email" 
												name="email" 
												placeholder="Enter email"
												onChange={handleChange}
												onBlur={handleBlur}
												value={values.email}			
											/>
											{touched.email && errors.email && (
												<ErrorMessage name="email" component="div" className='error-div'/>												
											)}
										</div>
										<div className="form-group col-md-12">
											<Field className={ touched.mobile && errors.mobile ? "form-control invalid" : "form-control" }
												type="text" 
												name="mobile" 
												placeholder="Enter mobile number"
												onChange={handleChange}
												onBlur={handleBlur}
												value={values.mobile}			
											/>
											{touched.mobile && errors.mobile && (
												<ErrorMessage name="mobile" component="div" className='error-div'/>												
											)}
										</div>
										
										<div className="form-group col-md-12">
											<textarea className="form-control" placeholder="Message"
												name="remarks"
												onChange={handleChange}
												onBlur={handleBlur}
												value={values.remarks}		
											></textarea>
										</div>
										<div className="form-group col-md-12" >
											{
												/*
												
											<div className="input-group">
												<span className="input-group-addon"style={{backgroundColor:"gray",verticalAlign:"middle",fontSize:11,paddingLeft:"8px",borderRadius:"8px 0px 0px 8px"}}>
													<img src={captchaSRC} style={{paddingTop:"13px",paddingLeft:"4px",paddingRight:"8px"}}/>
												</span>
												<input name="captcha" type="text" required className="form-control" placeholder="ENTER CAPTCHA"/>
											</div>
												*/
											}
											<ReCAPTCHA
												sitekey="6LfURBgUAAAAADngyL2tbL6TQsYajxVCBgeAchf1"
												onChange={onChange}
												onExpired={onExpired}
												asyncScriptOnLoad={onCaptchaLoad}
											/>
										</div>
										
										<div className="col-lg-12">
											<button name="submit" type="submit" className="btn w-100 btn-secondary btn-lg" disabled={isBookDisabled}>Submit</button>
										</div>
									</div>
								</form>
		)}</Formik>
							</div>									
							<div className="col-lg-3 col-sm-12 mb-30"></div>									
						</div>
					</div>
				</section>
			</div>			
		</>
	);
}


export default BookingForm;