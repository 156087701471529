import { useState, useEffect } from 'react';
import { Link, useNavigate, useLocation } from "react-router-dom";


import { Formik, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import BookingAPIService from '../../../api/BookingService';
import EmailAPIService from '../../../api/EmailService';

import ReCAPTCHA from 'react-google-recaptcha';

// Import Images
import bnrImg1 from "../../../images/banner/img1.jpg";
import waveBlue from "../../../images/shap/wave-blue.png";
import circleDots from "../../../images/shap/circle-dots.png";
import plusBlue from "../../../images/shap/plus-blue.png";
import servicesPic1 from "../../../images/services/corpo.png";
import Downloadables from '../../elements/downloadables';


const CorporateEyeExam = () => {

	
	const navigate = useNavigate();
	const location = useLocation();

	const [requestMessage,setRequestMessage] = useState("");
	const [isBookDisabled,setIsBookDisabled] = useState(true);

	const apiService = new EmailAPIService();


	const onSubmit = async (values, { setErrors, setStatus, setSubmitting ,resetForm }) => {     
        setStatus({ success: false });
        setSubmitting(false);           
		console.log("Submiting!");     

        try {  
            console.log(values);
            setSubmitting(true);  
			const resp = await apiService.submitEmail(values);
			
			console.log(resp?.status)
			if(resp?.status >= 200){
				console.log("Mailed");
				resetForm();	
				alert("Email has been sent!");			
			}

        } catch (err) {
            setStatus({ success: false });
            setErrors({ submit: err.message });                        
            setSubmitting(false);
        }

    };

	const onChange = (e) => {
		setIsBookDisabled(false);
	}

	const onExpired = (e) =>{
		setIsBookDisabled(true);
	}

	const onCaptchaLoad = () =>{
		
	}

	const initValues = {
		company : "",
		position : "",
		name : "",
		email : "",
		phone : "",
		message : "",
	}

	const validationScheme = Yup.object().shape({        
		name : Yup.string().min(1).max(120).required('Name is required!'),
		company : Yup.string().min(1).max(120).required('Name is required!'),
		position : Yup.string().min(1).max(120).required('Position is required!'),
		email : Yup.string().min(1).max(120).email('Must be a valid email').required('Email is required!'),
		phone : Yup.string().min(1).max(120).required('Mobile number is required!').matches('^(639)\\d{9}$','Invalid Mobile number format (639173334444)'),     
    })


	return (
		<>
			<div className="page-content bg-white">

				<div className="banner-wraper">
					<div className="page-banner" style={{ backgroundImage: "url(" + bnrImg1 + ")" }}>
						<div className="container">
							<div className="page-banner-entry text-center">
								<h1>Services</h1>
								<nav aria-label="breadcrumb" className="breadcrumb-row">
									<ul className="breadcrumb">
										<li className="breadcrumb-item"><Link to="/"><svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-home"><path d="M3 9l9-7 9 7v11a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2z"></path><polyline points="9 22 9 12 15 12 15 22"></polyline></svg> Home</Link></li>
										<li className="breadcrumb-item"><Link to="/services">Services</Link></li>
										<li className="breadcrumb-item active" aria-current="page">Standard Eye Examination</li>
									</ul>
								</nav>
							</div>
						</div>
						<img className="pt-img1 animate-wave" src={waveBlue} alt="" />
						<img className="pt-img2 animate2" src={circleDots} alt="" />
						<img className="pt-img3 animate-rotate" src={plusBlue} alt="" />
					</div>
				</div>

				<section className="section-area section-sp1">
					<div className="container">
						<div className="row">
							<div className="col-lg-8 mb-30">
								<div className="ttr-media mb-30">
									<img src={servicesPic1} className="rounded" alt=""/>
								</div>
								<div className="clearfix">
									<div className="head-text mb-30">
										<h2 className="title mb-15">Corporate Eye Care Program</h2>
										<p className="nse-paragraph mb-2">Nakpil Eye Clinic has been known to provide excellent eye and vision care for many years. And now through our Corporate Eye Care program we can bring the same excellent service to your company on your next annual eye examination. We use the latest technology to provide state of the art eye examinations and specialist services to ensure that your employees and staffs will receive the best eye-care. In addition, we can also conduct workstation assessment and provide necessary advice for them to have the safest, the healthiest, and the most visually effective working conditions.</p>
										<p className="nse-paragraph mb-0">Our corporate scheme enables companies to fulfill their health and safety obligations for VDU users and other workers with minimal administration work and expenditure and provides employees with large discounts on our products and services.</p>
									</div>
								</div>
								<div className="clearfix">
									<div className="head-text mb-30">
										<h4 className="title mb-10 mt-10">Inclusions of the Corporate Eye Care Program</h4>
										<h6 className="text-info mb-10 mt-30" >1. Blood Pressure Test</h6>
										<p className="nse-paragraph mb-10">Begin with a foundational assessment of your general health through a blood pressure test, recognizing the interconnectedness of systemic health and ocular well-being.</p>

										<h6 className="text-info mb-10 mt-30">2. Visual Acuity Assessment</h6>
										<p className="nse-paragraph mb-10">Measure the sharpness and clarity of your vision, identifying any refractive errors that may affect your visual acuity.</p>


										<h6 className="text-info mb-10 mt-30">3. Eye Motility Assessment</h6>
										<p className="nse-paragraph mb-10">Evaluate the coordination and movement of your eye muscles, ensuring optimal functionality and identifying potential issues.</p>


										<h6 className="text-info mb-10 mt-30">4. Basic Color Vision Assessment</h6>
										<p className="nse-paragraph mb-10">Explore your ability to perceive and differentiate colors accurately, assessing the health of your color vision.</p>


										<h6 className="text-info mb-10 mt-30">5. Physical Eye Examination (Bio-microscopy / Ophthalmoscopy)</h6>
										<p className="nse-paragraph mb-10">Delve into the intricate details of your eye's structures through advanced tools, allowing for a comprehensive physical examination.</p>


										<h6 className="text-info mb-10 mt-30">6. Standard Dry Eye Assessment</h6>
										<p className="nse-paragraph mb-10">Identify and address symptoms of dry eye syndrome, ensuring your eyes remain comfortable and well-lubricated.</p>


										<h6 className="text-info mb-10 mt-30">7. Cataract Screening</h6>
										<p className="nse-paragraph mb-10">Screen for early signs of cataracts, facilitating timely intervention and preserving your visual clarity.</p>


										<h6 className="text-info mb-10 mt-30">8. Glaucoma Screening VH Test</h6>
										<p className="nse-paragraph mb-10">Detects potential indicators of glaucoma through a comprehensive screening, emphasizing the importance of early diagnosis and management.</p>


										<h6 className="text-info mb-10 mt-30">9. Vision Examination & Correction (Objective / Subjective)</h6>
										<p className="nse-paragraph mb-10">Tailor your vision correction needs through both objective and subjective assessments, ensuring personalized and accurate prescriptions.</p>

									</div>
									<div className="head-text mb-100">
										<h4 className="title mb-10 mt-10">Additional Tests offered on Corporate rates (In clinic tests):</h4>
										<h6 className="text-info mb-10 mt-30" >1. Corneal Topography</h6>
										<h6 className="text-info mb-10 mt-30">2. IOP Tonometry (Eye pressure test)</h6>
										<h6 className="text-info mb-10 mt-30">3. Fundus Imaging Scan</h6>
										<h6 className="text-info mb-10 mt-30">4. OCT Optical Coherence Tomography</h6>


									</div>

									<div className="head-text mb-30">
										<h4 className="title mb-10 mt-10 mb-30">Send us a message to get your quotation.</h4>
										
										<Formik initialValues={initValues} validationSchema={validationScheme} onSubmit={onSubmit} validateOnChange={false} validateOnBlur={true}>
											{({ errors, handleBlur, handleChange, handleSubmit, isSubmitting, touched, values, setFieldValue }) => (
												<form className="form-wraper contact-form ajax-form" onSubmit={handleSubmit}>

													<div className="row">

														<div className="form-group col-md-12">
															<Field className={touched.name && errors.name ? "form-control invalid" : "form-control"}
																type="text"
																name="name"
																placeholder="Your Name"
																onChange={handleChange}
																onBlur={handleBlur}
																value={values.name}
															/>
															{touched.name && errors.name && (
																<ErrorMessage name="name" component="div" className='error-div' />
															)}
														</div>
														<div className="form-group col-md-12">
															<Field className={touched.company && errors.company ? "form-control invalid" : "form-control"}
																type="text"
																name="company"
																placeholder="Company"
																onChange={handleChange}
																onBlur={handleBlur}
																value={values.company}
															/>
															{touched.company && errors.company && (
																<ErrorMessage name="company" component="div" className='error-div' />
															)}
														</div>
														
														<div className="form-group col-md-12">
															<Field className={touched.position && errors.position ? "form-control invalid" : "form-control"}
																type="text"
																name="position"
																placeholder="Position"
																onChange={handleChange}
																onBlur={handleBlur}
																value={values.position}
															/>
															{touched.position && errors.position && (
																<ErrorMessage name="position" component="div" className='error-div' />
															)}
														</div>
														<div className="form-group col-md-12">
															<Field className={touched.email && errors.email ? "form-control invalid" : "form-control"}
																type="email"
																name="email"
																placeholder="Enter email"
																onChange={handleChange}
																onBlur={handleBlur}
																value={values.email}
															/>
															{touched.email && errors.email && (
																<ErrorMessage name="email" component="div" className='error-div' />
															)}
														</div>
														<div className="form-group col-md-12">
															<Field className={touched.phone && errors.phone ? "form-control invalid" : "form-control"}
																type="text"
																name="phone"
																placeholder="Enter contact number"
																onChange={handleChange}
																onBlur={handleBlur}
																value={values.phone}
															/>
															{touched.phone && errors.phone && (
																<ErrorMessage name="phone" component="div" className='error-div' />
															)}
														</div>

														<div className="form-group col-md-12">
															<textarea className="form-control" placeholder="Message"
																name="message"
																onChange={handleChange}
																onBlur={handleBlur}
																value={values.message}
															></textarea>
														</div>
														<div className="form-group col-md-12" >
															{
																/*
																
															<div className="input-group">
																<span className="input-group-addon"style={{backgroundColor:"gray",verticalAlign:"middle",fontSize:11,paddingLeft:"8px",borderRadius:"8px 0px 0px 8px"}}>
																	<img src={captchaSRC} style={{paddingTop:"13px",paddingLeft:"4px",paddingRight:"8px"}}/>
																</span>
																<input name="captcha" type="text" required className="form-control" placeholder="ENTER CAPTCHA"/>
															</div>
																*/
															}
															<ReCAPTCHA
																sitekey="6LfURBgUAAAAADngyL2tbL6TQsYajxVCBgeAchf1"
																onChange={onChange}
																onExpired={onExpired}
																asyncScriptOnLoad={onCaptchaLoad}
															/>
														</div>
														<div className="ajax-message mt-30 text-danger">{requestMessage}</div>
														<div className="col-lg-12">
															<button name="submit" type="submit" className="btn w-100 btn-secondary btn-lg" disabled={isBookDisabled}>Submit</button>
														</div>
													</div>
												</form>
											)}</Formik>
									</div>



								</div>
							</div>
							<div className="col-lg-4">
								<aside className="sticky-top pb-1">
									<div className="widget">
										<ul className="service-menu">
											<div className='heading-bx'>
												<h6 className="title-ext text-primary">Examination Packages</h6>
											</div>
											<li><Link to="/standardeyeexam"><span>Standard Eye Examination</span><i className="fa fa-angle-right"></i></Link></li>
											<li><Link to="/premiumeyeexam"><span>Premium Eye Examination</span><i className="fa fa-angle-right"></i></Link></li>
											<li><Link to="/pediatriceyeexam"><span>Pediatric Eye Examination</span><i className="fa fa-angle-right"></i></Link></li>
											<li><Link to="/executiveeyeexam"><span>Executive Eye Examination</span><i className="fa fa-angle-right"></i></Link></li>
											<li><Link to="/executiveeyeplusexam"><span>Executive Plus Eye Examination</span><i className="fa fa-angle-right"></i></Link></li>
											<li className="active"><Link to="/corporateeyeexam"><span>Corporate Eye Care Program</span><i className="fa fa-angle-right"></i></Link></li>
											<li><Link to="/followupeyeexam"><span>Follow-up Eye Examination</span><i className="fa fa-angle-right"></i></Link></li>
										</ul>
									</div>
									<div className="widget">
										<ul className="service-menu">
											<div className='heading-bx'>
												<h6 className="title-ext text-primary">Other Services</h6>
											</div>
											<li><Link to="/biometry"><span>Biometry</span><i className="fa fa-angle-right"></i></Link></li>
											<li><Link to="/cycloplegicrefraction"><span>Cycloplegic Eyedrops</span><i className="fa fa-angle-right"></i></Link></li>
											<li><Link to="/foreignbdr"><span>Foreign Bodies Removal</span><i className="fa fa-angle-right"></i></Link></li>
											<li><Link to="/fundusairdoc"><span>Fundus Scan with AirDoc</span><i className="fa fa-angle-right"></i></Link></li>
											<li><Link to="/funduskanghua"><span>Fundus Scan with Kanghua</span><i className="fa fa-angle-right"></i></Link></li>
											<li><Link to="/iopassessment"><span>Intra Ocular Pressure Assessment</span><i className="fa fa-angle-right"></i></Link></li>
											<li><Link to="/ishiharatest"><span>Ishihara Color Vision Test</span><i className="fa fa-angle-right"></i></Link></li>
											<li><Link to="/ishiharafanswort"><span>Ishihara with Farnsworth</span><i className="fa fa-angle-right"></i></Link></li>
											<li><Link to="/orthokeratology"><span>OrthoKeratology Lens</span><i className="fa fa-angle-right"></i></Link></li>
											<li><Link to="/orthokeratologylens"><span>OrthoKeratology Lens Fitting</span><i className="fa fa-angle-right"></i></Link></li>
											<li><Link to="/perimetry"><span>Perimetry / Visual Field Test</span><i className="fa fa-angle-right"></i></Link></li>
											<li><Link to="/topography"><span>Topography</span><i className="fa fa-angle-right"></i></Link></li>
											<li><Link to="/visiontherapy"><span>Vision Therapy</span><i className="fa fa-angle-right"></i></Link></li>
											<li><Link to="/visualpa"><span>Visual Performance Assessment</span><i className="fa fa-angle-right"></i></Link></li>
										</ul>
									</div>
									<Downloadables />

								</aside>
							</div>
						</div>
					</div>
				</section>

			</div>

		</>
	);
}



export default CorporateEyeExam;