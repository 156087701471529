import React, {Component} from 'react';
import {Link} from 'react-router-dom';
import {Accordion} from 'react-bootstrap';

// Import Images
import bnrImg1 from "../../../images/banner/img1.jpg";
import waveBlue from "../../../images/shap/wave-blue.png";
import circleDots from "../../../images/shap/circle-dots.png";
import plusBlue from "../../../images/shap/plus-blue.png";
import servicesPic1 from "../../../images/services/topography.jpg";
import Downloadables from '../../elements/downloadables';

class VisionTherapy extends Component{
	
	render(){
		return (
			<>
				
				<div className="page-content bg-white">
					
					<div className="banner-wraper">
						<div className="page-banner" style={{backgroundImage: "url("+bnrImg1+")"}}>
							<div className="container">
								<div className="page-banner-entry text-center">
									<h1>Services</h1>
									<nav aria-label="breadcrumb" className="breadcrumb-row">
										<ul className="breadcrumb">
											<li className="breadcrumb-item"><Link to="/"><svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-home"><path d="M3 9l9-7 9 7v11a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2z"></path><polyline points="9 22 9 12 15 12 15 22"></polyline></svg> Home</Link></li>
											<li className="breadcrumb-item"><Link to="/services">Services</Link></li>
											<li className="breadcrumb-item active" aria-current="page">Vision Therapy</li>
										</ul>
									</nav>
								</div>
							</div>
							<img className="pt-img1 animate-wave" src={waveBlue} alt=""/>
							<img className="pt-img2 animate2" src={circleDots} alt=""/>
							<img className="pt-img3 animate-rotate" src={plusBlue} alt=""/>
						</div>
					</div>
					
					<section className="section-area section-sp1">
						<div className="container">
							<div className="row">
								<div className="col-lg-8 mb-30">
									{
										/*										
										<div className="ttr-media mb-30">
											<img src={servicesPic1} className="rounded" alt=""/>
										</div>
										*/
									}
									<div className="clearfix">
										<div className="head-text mb-30">
											<h2 className="title mb-15">Vision Therapy</h2>
											<p className="nse-paragraph mb-30">At Nakpil Eye Clinic, we understand that clear vision is fundamental to a fulfilling and productive life. Our Vision Therapy services are designed to address a variety of visual challenges, enhance visual comfort, and optimize overall visual performance. Whether you're struggling with a specific visual condition, aiming to improve sports performance, or simply seeking to enhance your visual skills, our comprehensive vision therapy program is tailored to meet your unique needs.</p>																						
											<p className="nse-paragraph mb-0">At the heart of our Vision Therapy services is a patient-centric approach that combines cutting-edge technology with personalized care. Our experienced and certified vision therapists, trained by our Medical Director, Dr. John Nakpil, works closely with each individual, utilizing a holistic methodology that goes beyond traditional eye care. We believe in empowering our patients to achieve their full visual potential through a combination of therapeutic exercises, state-of-the-art equipment, and a supportive, nurturing environment.</p>			
										</div>										
									</div>
									<div className="clearfix">
										<div className="head-text mb-30">
											<div className='heading-bx mt-5 mb-5'>
												<h6 className="title-ext text-primary">Key Focus Areas</h6>
											</div>										
											<h6 className="text-info mb-10 mt-30" >1. Visual Rehabilitation</h6>
											<p className="nse-paragraph mb-10">Addressing conditions such as amblyopia, strabismus, and other visual disorders to improve eye coordination and functionality.</p>

											<h6 className="text-info mb-10 mt-30">2. Sports Vision Enhancement</h6>
											<p className="nse-paragraph mb-10">Tailored programs for athletes seeking to elevate their visual skills, hand-eye coordination, and reaction time for peak performance in sports.</p>

											
											<h6 className="text-info mb-10 mt-30">3. Reading and Learning Enhancement</h6>
											<p className="nse-paragraph mb-10">Targeting visual processing issues to improve reading fluency, comprehension, and overall learning abilities.</p>

											
											<h6 className="text-info mb-10 mt-30">4. Computer Vision Syndrome (CVS) Management</h6>
											<p className="nse-paragraph mb-10">Alleviating symptoms associated with prolonged computer use, such as eyestrain, headaches, and fatigue.</p>

											<h4 className="title mb-10 mt-30">Why Choose Nakpil Eye Clinic for Vision Therapy?</h4>			
											<div className="ml-30">	
												<ul>
													<li className='nse-list-group-item'>Experienced Team: Our dedicated team of certified vision therapists and eye care professionals brings years of experience and expertise to the field of vision therapy.</li>
													<li className='nse-list-group-item'>Cutting-Edge Technology: We invest in the latest diagnostic and therapeutic technologies to ensure the most effective and advanced vision therapy services.</li>
													<li className='nse-list-group-item'>Individualized Programs: Every patient is unique, and so is their vision therapy journey. Our programs are personalized to address specific needs, goals, and timelines.</li>
													<li className='nse-list-group-item'>Comprehensive Care: We work in collaboration with eye care specialists, educators, and other professionals to provide holistic care that considers the broader aspects of visual health and wellness.</li>
												</ul>
											</div>

											<p className="nse-paragraph mb-0 mt-30">Get Started on Your Vision Journey! Embark on a transformative journey to improved vision and enhanced quality of life with Nakpil Eye Clinic’s Vision Therapy services. Schedule a consultation today, and let our team guide you towards a future of clearer, more comfortable vision.</p>
											<p className="nse-paragraph mb-0 mt-10">See the world with new clarity at Nakpil Eye Clinic – Your Eye and Vision Specialist.</p>
										</div>
										{
											/*
											<Accordion defaultActiveKey="0" className="accordion ttr-accordion1">
											<Accordion.Item eventKey="0">
												<Accordion.Header>Question 1</Accordion.Header>
												<Accordion.Body>
													<p className="mb-0">Answer 1</p>
												</Accordion.Body>
											</Accordion.Item>
											<Accordion.Item eventKey="1">
												<Accordion.Header>Question 2</Accordion.Header>
												<Accordion.Body>
													<p className="mb-0">Answer 2</p>
												</Accordion.Body>
											</Accordion.Item>
										</Accordion>
											*/
										}
										
									</div>
								</div>
								<div className="col-lg-4">
									<aside className="sticky-top pb-1">
										<div className="widget">										
											<ul className="service-menu">
												<div className='heading-bx'>
													<h6 className="title-ext text-primary">Examination Packages</h6>
												</div>
												<li><Link to="/standardeyeexam"><span>Standard Eye Examination</span><i className="fa fa-angle-right"></i></Link></li>
												<li><Link to="/premiumeyeexam"><span>Premium Eye Examination</span><i className="fa fa-angle-right"></i></Link></li>
												<li><Link to="/pediatriceyeexam"><span>Pediatric Eye Examination</span><i className="fa fa-angle-right"></i></Link></li>
												<li><Link to="/executiveeyeexam"><span>Executive Eye Examination</span><i className="fa fa-angle-right"></i></Link></li>
												<li><Link to="/executiveeyeplusexam"><span>Executive Plus Eye Examination</span><i className="fa fa-angle-right"></i></Link></li>
												<li><Link to="/corporateeyeexam"><span>Corporate Eye Care Program</span><i className="fa fa-angle-right"></i></Link></li>
												<li><Link to="/followupeyeexam"><span>Follow-up Eye Examination</span><i className="fa fa-angle-right"></i></Link></li>
											</ul>
										</div>
										<div className="widget">
											<ul className="service-menu">
												<div className='heading-bx'>
													<h6 className="title-ext text-primary">Other Services</h6>
												</div>
												<li><Link to="/biometry"><span>Biometry</span><i className="fa fa-angle-right"></i></Link></li>
												<li><Link to="/cycloplegicrefraction"><span>Cycloplegic Eyedrops</span><i className="fa fa-angle-right"></i></Link></li>
												<li><Link to="/foreignbdr"><span>Foreign Bodies Removal</span><i className="fa fa-angle-right"></i></Link></li>
												<li><Link to="/fundusairdoc"><span>Fundus Scan with AirDoc</span><i className="fa fa-angle-right"></i></Link></li>
												<li><Link to="/funduskanghua"><span>Fundus Scan with Kanghua</span><i className="fa fa-angle-right"></i></Link></li>
												<li><Link to="/iopassessment"><span>Intra Ocular Pressure Assessment</span><i className="fa fa-angle-right"></i></Link></li>
												<li><Link to="/ishiharatest"><span>Ishihara Color Vision Test</span><i className="fa fa-angle-right"></i></Link></li>
												<li><Link to="/ishiharafanswort"><span>Ishihara with Farnsworth</span><i className="fa fa-angle-right"></i></Link></li>
												<li><Link to="/orthokeratology"><span>OrthoKeratology Lens</span><i className="fa fa-angle-right"></i></Link></li>
												<li><Link to="/orthokeratologylens"><span>OrthoKeratology Lens Fitting</span><i className="fa fa-angle-right"></i></Link></li>
												<li><Link to="/perimetry"><span>Perimetry / Visual Field Test</span><i className="fa fa-angle-right"></i></Link></li>
												<li><Link to="/topography"><span>Topography</span><i className="fa fa-angle-right"></i></Link></li>
												<li className="active"><Link to="/visiontherapy"><span>Vision Therapy</span><i className="fa fa-angle-right"></i></Link></li>
												<li><Link to="/visualpa"><span>Visual Performance Assessment</span><i className="fa fa-angle-right"></i></Link></li>
											</ul>
										</div>
										<Downloadables/>
										
									</aside>
								</div>
							</div>
						</div>
					</section>
					
				</div>
				
			</>
		);
	}
}

export default VisionTherapy;