import React, {Component} from 'react';
import {Link} from 'react-router-dom';
import {Accordion} from 'react-bootstrap';

// Import Images
import bnrImg1 from "../../../images/banner/img1.jpg";
import waveBlue from "../../../images/shap/wave-blue.png";
import circleDots from "../../../images/shap/circle-dots.png";
import plusBlue from "../../../images/shap/plus-blue.png";
import servicesPic1 from "../../../images/noimage.jpg";
import Downloadables from '../../elements/downloadables';

class SynoptophoreDetail extends Component{
	
	render(){
		return (
			<>
				
				<div className="page-content bg-white">
					
					<div className="banner-wraper">
						<div className="page-banner" style={{backgroundImage: "url("+bnrImg1+")"}}>
							<div className="container">
								<div className="page-banner-entry text-center">
									<h1>Eye Tests</h1>
									<nav aria-label="breadcrumb" className="breadcrumb-row">
										<ul className="breadcrumb">
											<li className="breadcrumb-item"><Link to="/"><svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-home"><path d="M3 9l9-7 9 7v11a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2z"></path><polyline points="9 22 9 12 15 12 15 22"></polyline></svg> Home</Link></li>
											<li className="breadcrumb-item"><Link to="/">Eye Tests</Link></li>
											<li className="breadcrumb-item active" aria-current="page">Synoptophore Test</li>
										</ul>
									</nav>
								</div>
							</div>
							<img className="pt-img1 animate-wave" src={waveBlue} alt=""/>
							<img className="pt-img2 animate2" src={circleDots} alt=""/>
							<img className="pt-img3 animate-rotate" src={plusBlue} alt=""/>
						</div>
					</div>
					
					<section className="section-area section-sp1">
						<div className="container">
							<div className="row">
								<div className="col-lg-8 mb-30">
									{/*
									<div className="ttr-media mb-30">
										<img src={servicesPic1} className="rounded" alt=""/>
									</div>
									*/}
									<div className="clearfix">
										<div className="head-text mb-30">
											<h2 className="title mb-15">Synoptophore Test</h2>
											<p className="nse-paragraph mb-0">A synoptophore, often referred to as a synoptometer, is a specialized binocular vision testing instrument that we use at Nakpil Eye Clinic to evaluate and treat various eye conditions, particularly those related to binocular vision and eye alignment. The instrument consists of two optical tubes that can be independently adjusted to present different images to each eye.</p>											
										</div>										
									</div>
									<div className="clearfix">
										<div className="head-text mb-30">
											<h4 className="title mb-10 mt-10">The primary purposes of a synoptophore are : </h4>
											<div className="ml-30">	
												<ul>
													<li className='nse-list-group-item'>Assessment of Binocular Vision: A synoptophore helps eye care specialists assess how well the eyes work together, focusing on their ability to converge (move inward) and diverge (move outward) as a coordinated team. It is particularly useful for diagnosing and measuring strabismus (eye misalignment), as well as evaluating the degree of suppression (the brain's way of ignoring input from one eye) in cases of amblyopia (lazy eye).</li>
													<li className='nse-list-group-item'>Management of Strabismus: The synoptophore is a key tool in the management of strabismus. By assessing the degree of eye misalignment and training the eyes to work together, it helps in planning treatment approaches, which may include vision therapy, eye muscle surgery, or the prescription of prismatic lenses.</li>
													<li className='nse-list-group-item'>Diplopia Evaluation: The instrument is used to assess and quantify double vision (diplopia) and can be employed to create controlled situations where diplopia can be measured and managed effectively.</li>
												</ul>
											</div>										
											<h4 className="title mb-10 mt-10">Here is how a synoptophore is typically used : </h4>
											<div className="ml-30">	
												<ul>
													<li className='nse-list-group-item'>Exercises: The patient is asked to perform various exercises to align and coordinate the eyes. These exercises may include trying to merge images, focus on a single target, or follow moving targets.</li>
													<li className='nse-list-group-item'>Measurement: The instrument provides precise measurements of the degree of eye deviation, the angle of strabismus, and the range of binocular vision.</li>
													<li className='nse-list-group-item'>Training: In cases of strabismus or amblyopia, the synoptophore can be used in vision therapy programs to help the patient improve binocular vision and eye alignment.</li>
												</ul>
											</div>

											<p className="nse-paragraph mb-0 mt-30">The synoptophore is a valuable tool for diagnosing and managing binocular vision disorders and can significantly contribute to treatment planning and monitoring. The results obtained from synoptophore assessments assist eye care specialists in tailoring appropriate interventions for each patient's specific visual needs.</p>
										</div>
									</div>
								</div>
								<div className="col-lg-4">
									<aside className="sticky-top pb-1">
										<div className="widget">
											<ul className="service-menu">
												<li ><Link to="/biomicroscopy-tests"><span>Bio Microscopy</span><i className="fa fa-angle-right"></i></Link></li>
												<li><Link to="/cornealtopography-tests"><span>Corneal Topography</span><i className="fa fa-angle-right"></i></Link></li>
												<li><Link to="/dryeye-tests"><span>Dry Eye Test</span><i className="fa fa-angle-right"></i></Link></li>
												<li><Link to="/fundoscopy-tests"><span>Fundoscopy</span><i className="fa fa-angle-right"></i></Link></li>
												<li><Link to="/keratometry-tests"><span>Keratometry</span><i className="fa fa-angle-right"></i></Link></li>
												<li><Link to="/meibomianglandassessment-tests"><span>Meibomian gland Assessment</span><i className="fa fa-angle-right"></i></Link></li>
												<li><Link to="/ocularbiometry-tests"><span>Ocular Biometry</span><i className="fa fa-angle-right"></i></Link></li>
												<li><Link to="/opticalcoherencetomography-tests"><span>Optical Coherence Tomography (OCT)</span><i className="fa fa-angle-right"></i></Link></li>
												<li><Link to="/pachymetry-tests"><span>Pachymetry</span><i className="fa fa-angle-right"></i></Link></li>
												<li><Link to="/perimetry-tests"><span>Perimetry</span><i className="fa fa-angle-right"></i></Link></li>
												<li><Link to="/refraction-tests"><span>Refraction</span><i className="fa fa-angle-right"></i></Link></li>
												<li><Link to="/sensorymotor-tests"><span>Sensory Motor Test</span><i className="fa fa-angle-right"></i></Link></li>
												<li className="active"><Link to="/synoptophore-tests"><span>Synoptophore test</span><i className="fa fa-angle-right"></i></Link></li>
												<li><Link to="/tonometry-tests"><span>Tonometry</span><i className="fa fa-angle-right"></i></Link></li>
											</ul>
										</div>
										<Downloadables/>
										
									</aside>
								</div>
							</div>
						</div>
					</section>
					
				</div>
				
			</>
		);
	}
}

export default SynoptophoreDetail;