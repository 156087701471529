import React, {Component} from 'react';
import {Link} from 'react-router-dom';
import {Accordion} from 'react-bootstrap';

// Import Images
import bnrImg1 from "../../../images/banner/img1.jpg";
import waveBlue from "../../../images/shap/wave-blue.png";
import circleDots from "../../../images/shap/circle-dots.png";
import plusBlue from "../../../images/shap/plus-blue.png";
import servicesPic1 from "../../../images/services/topography.jpg";
import Downloadables from '../../elements/downloadables';

class Topography extends Component{
	
	render(){
		return (
			<>
				
				<div className="page-content bg-white">
					
					<div className="banner-wraper">
						<div className="page-banner" style={{backgroundImage: "url("+bnrImg1+")"}}>
							<div className="container">
								<div className="page-banner-entry text-center">
									<h1>Services</h1>
									<nav aria-label="breadcrumb" className="breadcrumb-row">
										<ul className="breadcrumb">
											<li className="breadcrumb-item"><Link to="/"><svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-home"><path d="M3 9l9-7 9 7v11a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2z"></path><polyline points="9 22 9 12 15 12 15 22"></polyline></svg> Home</Link></li>
											<li className="breadcrumb-item"><Link to="/services">Services</Link></li>
											<li className="breadcrumb-item active" aria-current="page">Topography</li>
										</ul>
									</nav>
								</div>
							</div>
							<img className="pt-img1 animate-wave" src={waveBlue} alt=""/>
							<img className="pt-img2 animate2" src={circleDots} alt=""/>
							<img className="pt-img3 animate-rotate" src={plusBlue} alt=""/>
						</div>
					</div>
					
					<section className="section-area section-sp1">
						<div className="container">
							<div className="row">
								<div className="col-lg-8 mb-30">
									<div className="ttr-media mb-30">
										<img src={servicesPic1} className="rounded" alt=""/>
									</div>
									<div className="clearfix">
										<div className="head-text mb-30">
											<h2 className="title mb-15">Topography</h2>
											<p className="nse-paragraph mb-30">Welcome to Nakpil Eye Clinic, where vision meets precision! Our commitment to providing cutting-edge eye care services continues with our advanced Topography test. Designed to map the unique landscape of your cornea, this procedure offers a detailed analysis of your eye's surface, enabling our expert optometrists and  an ophthalmologist to tailor precise solutions for your visual needs.</p>																						
											<div className='heading-bx mt-5 mb-5'>
												<h6 className="title-ext text-primary">What is Topography?</h6>
											</div>	
											<p className="nse-paragraph mb-0">Topography is a state-of-the-art diagnostic procedure that captures a three-dimensional map of the cornea, the transparent front part of the eye. This comprehensive mapping allows us to understand the curvature, shape, and irregularities of the cornea, providing invaluable insights into various eye conditions.</p>			
										</div>										
									</div>
									<div className="clearfix">
										<div className="head-text mb-30">
											<div className='heading-bx mt-5 mb-5'>
												<h6 className="title-ext text-primary">Key Benefits of Topography procedure</h6>
											</div>										
											<h6 className="text-info mb-10 mt-30" >1. Customized Vision Correction</h6>
											<p className="nse-paragraph mb-10">With detailed corneal maps, we can design personalized solutions for vision correction, including customized contact lenses or laser eye surgery.</p>

											<h6 className="text-info mb-10 mt-30">2. Early Detection of Conditions</h6>
											<p className="nse-paragraph mb-10">Topography aids in the early detection of conditions such as keratoconus, corneal irregularities, and astigmatism, enabling timely intervention and management.</p>

											
											<h6 className="text-info mb-10 mt-30">3. Enhanced Contact Lens Fitting</h6>
											<p className="nse-paragraph mb-10">Achieve optimal comfort and vision with contact lenses tailored to the unique contours of your cornea, minimizing discomfort and maximizing clarity.</p>

											
											<h6 className="text-info mb-10 mt-30">4. Precise Refractive Surgery Planning</h6>
											<p className="nse-paragraph mb-10">For those considering refractive surgeries like LASIK, our Topography test ensures accurate surgical planning, resulting in improved outcomes.</p>

											<h4 className="title mb-10 mt-30">Why Choose Nakpil Eye Clinic for Topography?</h4>			
											<div className="ml-30">	
												<ul>
													<li className='nse-list-group-item'>Expertise: Our team of skilled optometrists and an ophthalmologist specializes in utilizing topography for precise diagnostics and personalized treatment plans.</li>
													<li className='nse-list-group-item'>Cutting-Edge Technology: We invest in the latest topography technology to provide accurate and detailed corneal maps for a thorough assessment.</li>
													<li className='nse-list-group-item'>Patient-Centric Care: We prioritize your comfort and well-being, ensuring a seamless and informative experience throughout the topography procedure.</li>
													<li className='nse-list-group-item'>Comprehensive Eye Care: Beyond topography, our clinic offers a full range of eye care services to address all your visual health needs.</li>
													
												</ul>
											</div>

											

											<p className="nse-paragraph mb-0 mt-30">Invest in Your Visual Precision. Uncover the details of your corneal landscape with the Topography Procedure at Nakpil Eye Clinic. Experience the clarity and precision that come with personalized eye care.</p>
											<p className="nse-paragraph mb-0 mt-10">See the world in sharp focus at Nakpil Eye Clinic – Your Eye and Vision Specialist.</p>

											
										</div>
										{
											/*
											<Accordion defaultActiveKey="0" className="accordion ttr-accordion1">
											<Accordion.Item eventKey="0">
												<Accordion.Header>Question 1</Accordion.Header>
												<Accordion.Body>
													<p className="mb-0">Answer 1</p>
												</Accordion.Body>
											</Accordion.Item>
											<Accordion.Item eventKey="1">
												<Accordion.Header>Question 2</Accordion.Header>
												<Accordion.Body>
													<p className="mb-0">Answer 2</p>
												</Accordion.Body>
											</Accordion.Item>
										</Accordion>
											*/
										}
										
									</div>
								</div>
								<div className="col-lg-4">
									<aside className="sticky-top pb-1">
										<div className="widget">										
											<ul className="service-menu">
												<div className='heading-bx'>
													<h6 className="title-ext text-primary">Examination Packages</h6>
												</div>
												<li><Link to="/standardeyeexam"><span>Standard Eye Examination</span><i className="fa fa-angle-right"></i></Link></li>
												<li><Link to="/premiumeyeexam"><span>Premium Eye Examination</span><i className="fa fa-angle-right"></i></Link></li>
												<li><Link to="/pediatriceyeexam"><span>Pediatric Eye Examination</span><i className="fa fa-angle-right"></i></Link></li>
												<li><Link to="/executiveeyeexam"><span>Executive Eye Examination</span><i className="fa fa-angle-right"></i></Link></li>
												<li><Link to="/executiveeyeplusexam"><span>Executive Plus Eye Examination</span><i className="fa fa-angle-right"></i></Link></li>
												<li><Link to="/corporateeyeexam"><span>Corporate Eye Care Program</span><i className="fa fa-angle-right"></i></Link></li>
												<li><Link to="/followupeyeexam"><span>Follow-up Eye Examination</span><i className="fa fa-angle-right"></i></Link></li>
											</ul>
										</div>
										<div className="widget">
											<ul className="service-menu">
												<div className='heading-bx'>
													<h6 className="title-ext text-primary">Other Services</h6>
												</div>
												<li><Link to="/biometry"><span>Biometry</span><i className="fa fa-angle-right"></i></Link></li>
												<li><Link to="/cycloplegicrefraction"><span>Cycloplegic Eyedrops</span><i className="fa fa-angle-right"></i></Link></li>
												<li><Link to="/foreignbdr"><span>Foreign Bodies Removal</span><i className="fa fa-angle-right"></i></Link></li>
												<li><Link to="/fundusairdoc"><span>Fundus Scan with AirDoc</span><i className="fa fa-angle-right"></i></Link></li>
												<li><Link to="/funduskanghua"><span>Fundus Scan with Kanghua</span><i className="fa fa-angle-right"></i></Link></li>
												<li><Link to="/iopassessment"><span>Intra Ocular Pressure Assessment</span><i className="fa fa-angle-right"></i></Link></li>
												<li><Link to="/ishiharatest"><span>Ishihara Color Vision Test</span><i className="fa fa-angle-right"></i></Link></li>
												<li><Link to="/ishiharafanswort"><span>Ishihara with Farnsworth</span><i className="fa fa-angle-right"></i></Link></li>
												<li><Link to="/orthokeratology"><span>OrthoKeratology Lens</span><i className="fa fa-angle-right"></i></Link></li>
												<li><Link to="/orthokeratologylens"><span>OrthoKeratology Lens Fitting</span><i className="fa fa-angle-right"></i></Link></li>
												<li><Link to="/perimetry"><span>Perimetry / Visual Field Test</span><i className="fa fa-angle-right"></i></Link></li>
												<li className="active"><Link to="/topography"><span>Topography</span><i className="fa fa-angle-right"></i></Link></li>
												<li><Link to="/visiontherapy"><span>Vision Therapy</span><i className="fa fa-angle-right"></i></Link></li>
												<li><Link to="/visualpa"><span>Visual Performance Assessment</span><i className="fa fa-angle-right"></i></Link></li>
											</ul>
										</div>
										<Downloadables/>
										
									</aside>
								</div>
							</div>
						</div>
					</section>
					
				</div>
				
			</>
		);
	}
}

export default Topography;