import React, {Component} from 'react';
import {Link} from 'react-router-dom';
import {Accordion} from 'react-bootstrap';

// Elements
import LatestNewsSection from "../elements/latest-news-slider";

// Import Images
import bnrImg1 from "../../images/banner/img1.jpg";
import waveBlue from "../../images/shap/wave-blue.png";
import circleDots from "../../images/shap/circle-dots.png";
import plusBlue from "../../images/shap/plus-blue.png";

class Faq extends Component{
	
	render(){
		return (
			<>
				
				<div className="page-content bg-white">
					
					<div className="banner-wraper">
						<div className="page-banner" style={{backgroundImage: "url("+bnrImg1+")"}}>
							<div className="container">
								<div className="page-banner-entry text-center">
									<h1>Faq's</h1>
									<nav aria-label="breadcrumb" className="breadcrumb-row">
										<ul className="breadcrumb">
											<li className="breadcrumb-item"><Link to="/"><svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-home"><path d="M3 9l9-7 9 7v11a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2z"></path><polyline points="9 22 9 12 15 12 15 22"></polyline></svg> Home</Link></li>
											<li className="breadcrumb-item active" aria-current="page">Faq's</li>
										</ul>
									</nav>
								</div>
							</div>
							<img className="pt-img1 animate-wave" src={waveBlue} alt=""/>
							<img className="pt-img2 animate2" src={circleDots} alt=""/>
							<img className="pt-img3 animate-rotate" src={plusBlue} alt=""/>
						</div>
					</div>
					
					<section className="section-sp3">
						<div className="container">
							<div className="row">
								<div className="col-lg-6">
									<Accordion defaultActiveKey="0" className="accordion ttr-accordion1">
										<Accordion.Item eventKey="0">
											<Accordion.Header>What is optometry? </Accordion.Header>
											<Accordion.Body>
												<p className="mb-0">Optometry is a healthcare profession that focuses on eye health and vision problems.</p>
											</Accordion.Body>
										</Accordion.Item>
										<Accordion.Item eventKey="1">
											<Accordion.Header>What do optometrists do?</Accordion.Header>
											<Accordion.Body>
												<p className="mb-0">Optometrists examine, diagnose, treat, and manage eye health problems. They can also write prescriptions for conditions like glaucoma and dry eye syndrome. </p>
											</Accordion.Body>
										</Accordion.Item>
										<Accordion.Item eventKey="2">
											<Accordion.Header>Why is eye care important?</Accordion.Header>
											<Accordion.Body>
												<p className="mb-0">Eye care is essential for maintaining good vision and overall eye health. Regular check-ups can help detect and prevent issues such as glaucoma, macular degeneration, and other eye conditions before they worsen.</p>
											</Accordion.Body>
										</Accordion.Item>
										<Accordion.Item eventKey="3">
											<Accordion.Header>How often should I get my eyes checked?</Accordion.Header>
											<Accordion.Body>
												<p className="mb-0">Adults should get their eyes checked every 1-2 years, depending on their age, risk factors, and whether they have any symptoms. Children should have their first eye exam at six months of age, around age three, and before entering school.</p>
											</Accordion.Body>
										</Accordion.Item>
										<Accordion.Item eventKey="4">
											<Accordion.Header>How can I protect my eyes from strain caused by screens?</Accordion.Header>
											<Accordion.Body>
												<p className="mb-0">To reduce eye strain, follow the 20-20-20 rule: every 20 minutes, take a 20-second break to look at something 20 feet away. Additionally, ensure proper lighting, adjust screen brightness, and use anti-glare screens.</p>
											</Accordion.Body>
										</Accordion.Item>
										<Accordion.Item eventKey="5">
											<Accordion.Header>What foods are good for eye health?</Accordion.Header>
											<Accordion.Body>
												<p className="mb-0">Foods rich in vitamins and antioxidants are great for your eyes. These include:</p>
												<ul className="list-check-squer mb-0">
													<li>- Carrots (Vitamin A)</li>
													<li>- Leafy greens like spinach and kale (Lutein and Zeaxanthin)</li>
													<li>- Fish like salmon and tuna (Omega-3 fatty acids)</li>
													<li>- Oranges and citrus fruits (Vitamin C)</li>
												</ul>
											</Accordion.Body>
										</Accordion.Item>
									</Accordion>
								</div>
								<div className="col-lg-6">
									<Accordion defaultActiveKey="2" className="accordion ttr-accordion1">
										<Accordion.Item eventKey="0">
											<Accordion.Header>What is blue light, and how does it affect my eyes?</Accordion.Header>
											<Accordion.Body>
												<p className="mb-0">Blue light is emitted by digital devices and can contribute to digital eye strain. Prolonged exposure might lead to discomfort. Use blue light filters or glasses to minimize its effects.</p>
											</Accordion.Body>
										</Accordion.Item>
										<Accordion.Item eventKey="1">
											<Accordion.Header>How do I know if my child needs glasses?</Accordion.Header>
											<Accordion.Body>
												<p className="mb-0">Signs your child might need glasses:</p>
												<ul className="list-check-squer mb-0">
													<li>- Squinting frequently</li>
													<li>- Sitting too close to the TV or holding books too close</li>
													<li>- Complaints about blurry vision or frequent headaches</li>
													<li>- Difficulty concentrating in class</li>
												</ul>
											</Accordion.Body>
										</Accordion.Item>
										<Accordion.Item eventKey="2">
											<Accordion.Header>What are UV rays, and how can I protect my eyes from them?</Accordion.Header>
											<Accordion.Body>
												<p className="mb-0">UV rays from sunlight can damage the eyes and increase the risk of cataracts and macular degeneration. Always wear sunglasses that block 100% of UVA and UVB radiation when outdoors.</p>
											</Accordion.Body>
										</Accordion.Item>
										<Accordion.Item eventKey="3">
											<Accordion.Header>What should I do in case of an eye injury?</Accordion.Header>
											<Accordion.Body>
												<p className="mb-0">For any eye injury, avoid rubbing the eye or applying pressure. Flush with clean water if needed and seek immediate medical attention to prevent further damage.</p>
											</Accordion.Body>
										</Accordion.Item>
										<Accordion.Item eventKey="4">
											<Accordion.Header>What is the difference between an optometrist and an ophthalmologist?</Accordion.Header>
											<Accordion.Body>
												<p className="mb-0">- Optometrists focus on vision correction (like prescribing glasses or contacts) and primary eye care. Ophthalmologists are medical doctors who specialize in eye surgery and more complex eye conditions.</p>
											</Accordion.Body>
										</Accordion.Item>
										<Accordion.Item eventKey="5">
											<Accordion.Header>Nakpil Eye Clinic and Vision Therapy Center?</Accordion.Header>
											<Accordion.Body>
												<p className="mb-0">Is an eye care facility dedicated to providing services related to eye health, vision correction, and potentially, vision therapy. Vision therapy involves specialized programs designed to improve visual skills such as focusing, tracking, and eye coordination, often used for treating conditions like lazy eye (amblyopia), crossed eyes (strabismus), or learning-related vision problems.</p>
											</Accordion.Body>
										</Accordion.Item>
									</Accordion>
								</div>
							</div>
						</div>
					</section>
					
					<LatestNewsSection />
					
				</div>
			
			</>
		);
	}
}

export default Faq;