import React, {Component} from 'react';
import {Link} from 'react-router-dom';
import {Accordion} from 'react-bootstrap';

// Import Images
import bnrImg1 from "../../../images/banner/img1.jpg";
import waveBlue from "../../../images/shap/wave-blue.png";
import circleDots from "../../../images/shap/circle-dots.png";
import plusBlue from "../../../images/shap/plus-blue.png";
import servicesPic1 from "../../../images/noimage.jpg";
import pdf from "../../../images/icon/pdf.png";
import doc from "../../../images/icon/doc.png";
import Downloadables from '../../elements/downloadables';

class PachymetryDetail extends Component{
	
	render(){
		return (
			<>
				
				<div className="page-content bg-white">
					
					<div className="banner-wraper">
						<div className="page-banner" style={{backgroundImage: "url("+bnrImg1+")"}}>
							<div className="container">
								<div className="page-banner-entry text-center">
									<h1>Eye Tests</h1>
									<nav aria-label="breadcrumb" className="breadcrumb-row">
										<ul className="breadcrumb">
											<li className="breadcrumb-item"><Link to="/"><svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-home"><path d="M3 9l9-7 9 7v11a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2z"></path><polyline points="9 22 9 12 15 12 15 22"></polyline></svg> Home</Link></li>
											<li className="breadcrumb-item"><Link to="/">Eye Tests</Link></li>
											<li className="breadcrumb-item active" aria-current="page">Pachymetry</li>
										</ul>
									</nav>
								</div>
							</div>
							<img className="pt-img1 animate-wave" src={waveBlue} alt=""/>
							<img className="pt-img2 animate2" src={circleDots} alt=""/>
							<img className="pt-img3 animate-rotate" src={plusBlue} alt=""/>
						</div>
					</div>
					
					<section className="section-area section-sp1">
						<div className="container">
							<div className="row">
								<div className="col-lg-8 mb-30">
									{/*
									<div className="ttr-media mb-30">
										<img src={servicesPic1} className="rounded" alt=""/>
									</div>
									*/}
									<div className="clearfix">
										<div className="head-text mb-30">
											<h2 className="title mb-15">Pachymetry</h2>
											<p className="nse-paragraph mb-0">Pachymetry is a diagnostic procedure that we do at Nakpil Eye Clinic to measure the thickness of the cornea, which is the clear, front surface of the eye.</p>
											<h4 className="title mb-10 mt-20">This test has several important purposes and benefits in the field of eye care:</h4>
										</div>										
									</div>
									<div className="clearfix">
										<div className="head-text mb-30">
											<h4 className="title mb-10 mt-10">1. Determining Corneal Thickness</h4>
											<div className="ml-30">	
												<ul>
													<li className='nse-list-group-item'>Glaucoma Assessment: Pachymetry is crucial in the assessment of glaucoma, a group of eye conditions that can lead to optic nerve damage and vision loss. Thinner corneas are associated with an increased risk of glaucoma, while thicker corneas may be protective.</li>
													<li className='nse-list-group-item'>Refractive Surgery Screening: Before refractive surgeries like LASIK or PRK, pachymetry helps determine whether the cornea has sufficient thickness for the procedure.</li>
													<li className='nse-list-group-item'>Contact Lens Fitting: Corneal thickness can impact the suitability of contact lens options. Pachymetry is valuable in selecting the most appropriate lens type and fit.</li>
												</ul>
											</div>

											<h4 className="title mb-10 mt-10">2. Glaucoma Management</h4>
											<div className="ml-30">	
												<ul>
													<li className='nse-list-group-item'>In glaucoma management, regular pachymetry measurements help eye care professionals assess the progression of the disease and the effectiveness of treatment. Changes in corneal thickness may indicate a worsening of glaucoma.</li>
												</ul>
											</div>

											<h4 className="title mb-10 mt-10">3. Monitoring Corneal Health</h4>
											<div className="ml-30">	
												<ul>
													<li className='nse-list-group-item'>Corneal thickness can provide insights into corneal health. Changes in corneal thickness may be associated with conditions such as keratoconus, Fuchs' endothelial dystrophy, or corneal edema.</li>
													<li className='nse-list-group-item'>Pachymetry is used to monitor corneal health in patients who have had corneal surgeries, particularly corneal transplants.</li>
												</ul>
											</div>

											<h4 className="title mb-10 mt-10">4. Customizing Treatment Plans</h4>
											<div className="ml-30">	
												<ul>
													<li className='nse-list-group-item'>Pachymetry data can guide the customization of treatment plans. For instance, in glaucoma management, the measurement helps determine the target intraocular pressure, accounting for the patient's corneal thickness.</li>
													<li className='nse-list-group-item'>In refractive surgery, it assists in calculating the amount of corneal tissue to be removed during the procedure.</li>
												</ul>
											</div>

											<h4 className="title mb-10 mt-10">5. Preventing Complications</h4>
											<div className="ml-30">	
												<ul>
													<li className='nse-list-group-item'>Pachymetry is essential for avoiding complications during refractive surgery. It ensures that there is enough corneal tissue to safely perform the surgery without weakening the cornea excessively.</li>
													<li className='nse-list-group-item'>It helps identify patients at risk of glaucoma, allowing for early intervention to prevent vision loss.</li>
												</ul>
											</div>

											<h4 className="title mb-10 mt-10">6. Research and Documentation</h4>
											<div className="ml-30">	
												<ul>
													<li className='nse-list-group-item'>Pachymetry measurements are valuable for research purposes and for maintaining a comprehensive record of a patient's corneal health history. It aids in understanding the relationship between corneal thickness and various eye conditions.</li>
												</ul>
											</div>

											<h4 className="title mb-10 mt-10">7. Early Detection and Intervention</h4>
											<div className="ml-30">	
												<ul>
													<li className='nse-list-group-item'>Regular pachymetry assessments can aid in the early detection of corneal and glaucoma-related changes, allowing for timely intervention and better outcomes.</li>
												</ul>
											</div>

											<h4 className="title mb-10 mt-10">8. Patient Education</h4>
											<div className="ml-30">	
												<ul>
													<li className='nse-list-group-item'>Pachymetry results can be used to educate patients about their eye health and the implications for conditions like glaucoma. This information can help motivate patients to comply with treatment plans and lifestyle recommendations.</li>
												</ul>
											</div>
											
											<p className="nse-paragraph mb-0 mt-30">In summary, the purpose and benefits of having a pachymetry test include assessing corneal thickness, evaluating the risk of glaucoma, monitoring corneal health, customizing treatment plans, preventing complications during eye surgeries, contributing to research, and promoting early detection and intervention for various eye conditions. Pachymetry is a valuable tool for eye care professionals in maintaining and enhancing the visual health of their patients.</p>										</div>
										
									</div>
								</div>
								<div className="col-lg-4">
									<aside className="sticky-top pb-1">
										<div className="widget">
											<ul className="service-menu">
												<li><Link to="/biomicroscopy-tests"><span>Bio Microscopy</span><i className="fa fa-angle-right"></i></Link></li>
												<li><Link to="/cornealtopography-tests"><span>Corneal Topography</span><i className="fa fa-angle-right"></i></Link></li>
												<li><Link to="/dryeye-tests"><span>Dry Eye Test</span><i className="fa fa-angle-right"></i></Link></li>
												<li><Link to="/fundoscopy-tests"><span>Fundoscopy</span><i className="fa fa-angle-right"></i></Link></li>
												<li><Link to="/keratometry-tests"><span>Keratometry</span><i className="fa fa-angle-right"></i></Link></li>
												<li><Link to="/meibomianglandassessment-tests"><span>Meibomian gland Assessment</span><i className="fa fa-angle-right"></i></Link></li>
												<li><Link to="/ocularbiometry-tests"><span>Ocular Biometry</span><i className="fa fa-angle-right"></i></Link></li>
												<li><Link to="/opticalcoherencetomography-tests"><span>Optical Coherence Tomography (OCT)</span><i className="fa fa-angle-right"></i></Link></li>
												<li className="active"><Link to="/pachymetry-tests"><span>Pachymetry</span><i className="fa fa-angle-right"></i></Link></li>
												<li><Link to="/perimetry-tests"><span>Perimetry</span><i className="fa fa-angle-right"></i></Link></li>
												<li><Link to="/refraction-tests"><span>Refraction</span><i className="fa fa-angle-right"></i></Link></li>
												<li><Link to="/sensorymotor-tests"><span>Sensory Motor Test</span><i className="fa fa-angle-right"></i></Link></li>
												<li><Link to="/synoptophore-tests"><span>Synoptophore test</span><i className="fa fa-angle-right"></i></Link></li>
												<li><Link to="/tonometry-tests"><span>Tonometry</span><i className="fa fa-angle-right"></i></Link></li>
											</ul>
										</div>
										<Downloadables/>
										
									</aside>
								</div>
							</div>
						</div>
					</section>
					
				</div>
				
			</>
		);
	}
}

export default PachymetryDetail;