import React, {Component} from 'react';
import {Link} from 'react-router-dom';
import {Accordion} from 'react-bootstrap';

// Import Images
import bnrImg1 from "../../../images/banner/img1.jpg";
import waveBlue from "../../../images/shap/wave-blue.png";
import circleDots from "../../../images/shap/circle-dots.png";
import plusBlue from "../../../images/shap/plus-blue.png";
import servicesPic1 from "../../../images/noimage.jpg";
import Downloadables from '../../elements/downloadables';

class SensoryMotorTestDetail extends Component{
	
	render(){
		return (
			<>
				
				<div className="page-content bg-white">
					
					<div className="banner-wraper">
						<div className="page-banner" style={{backgroundImage: "url("+bnrImg1+")"}}>
							<div className="container">
								<div className="page-banner-entry text-center">
									<h1>Eye Tests</h1>
									<nav aria-label="breadcrumb" className="breadcrumb-row">
										<ul className="breadcrumb">
											<li className="breadcrumb-item"><Link to="/"><svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-home"><path d="M3 9l9-7 9 7v11a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2z"></path><polyline points="9 22 9 12 15 12 15 22"></polyline></svg> Home</Link></li>
											<li className="breadcrumb-item"><Link to="/">Eye Tests</Link></li>
											<li className="breadcrumb-item active" aria-current="page">Sensory Motor Test</li>
										</ul>
									</nav>
								</div>
							</div>
							<img className="pt-img1 animate-wave" src={waveBlue} alt=""/>
							<img className="pt-img2 animate2" src={circleDots} alt=""/>
							<img className="pt-img3 animate-rotate" src={plusBlue} alt=""/>
						</div>
					</div>
					
					<section className="section-area section-sp1">
						<div className="container">
							<div className="row">
								<div className="col-lg-8 mb-30">
									{/*
									<div className="ttr-media mb-30">
										<img src={servicesPic1} className="rounded" alt=""/>
									</div>
									*/}
									<div className="clearfix">
										<div className="head-text mb-30">
											<h2 className="title mb-15">Sensory Motor Test</h2>
											<p className="nse-paragraph mb-3">Sensory Motor test is a complex evaluation test of the eye muscle coordination, fixation and visual processing. Nakpil Eye Clinic uses RightEye Technology, a computerized Sensory Motor assessment system that uses eye-tracking solutions for various applications, including sports vision training, rehabilitation, and assessment of visual and neurological conditions. RightEye's technology is designed to assess and improve eye movement and visual skills. The company may have introduced new products or features since that time, so I recommend checking their official website or contacting them directly for the most up-to-date information on their offerings.</p>											
											<p className="nse-paragraph mb-3">Regarding the "sensory motor test," it's important to note that the specific tests and assessments conducted using RightEye technology may vary depending on the intended application. However, in the context of assessing visual and motor skills, these tests typically focus on eye movements, visual tracking, and hand-eye coordination. Here's a general overview of what such tests may entail.</p>											
										</div>										
									</div>
									<div className="clearfix">
										<div className="head-text mb-30">
											<h4 className="title mb-10 mt-10">Eye Movement Assessment</h4>
											<div className="ml-30">	
												<ul>
													<li className='nse-list-group-item'>The assessment may involve tracking a moving target with your eyes or following a pattern on a screen.</li>
													<li className='nse-list-group-item'>It can measure saccades (rapid eye movements), smooth pursuits (smoothly following a moving object), and other eye movement patterns.</li>
												</ul>
											</div>
											
											<h4 className="title mb-10 mt-10">Visual Tracking</h4>
											<div className="ml-30">	
												<ul>
													<li className='nse-list-group-item'>Visual tracking tests assess your ability to visually follow an object's path, which is crucial for activities like reading, sports, and daily tasks.</li>
													<li className='nse-list-group-item'>You may be asked to track an object's movement on a screen or perform tasks that require precise hand-eye coordination.</li>
												</ul>
											</div>
											
											<h4 className="title mb-10 mt-10">Hand-Eye Coordination</h4>
											<div className="ml-30">	
												<ul>
													<li className='nse-list-group-item'>This involves assessing how well you can coordinate your eye movements with your hand movements.</li>
													<li className='nse-list-group-item'>You might perform tasks like tapping specific targets on a touchscreen or following a moving object with your finger.</li>
												</ul>
											</div>
											
											<h4 className="title mb-10 mt-10">Fixation</h4>
											<div className="ml-30">	
												<ul>
													<li className='nse-list-group-item'>Fixation refers to the ability to maintain your gaze on a specific point.</li>
													<li className='nse-list-group-item'>Tests may evaluate how well you can keep your eyes steady on a target or how quickly your eyes move between different targets.</li>
													<li className='nse-list-group-item'>While</li>
												</ul>
											</div>
											
											<h4 className="title mb-10 mt-10">Depth Perception</h4>
											<div className="ml-30">	
												<ul>
													<li className='nse-list-group-item'>Some assessments may include tasks to evaluate your depth perception or 3D vision.</li>
													<li className='nse-list-group-item'>This can be important in sports and activities that require judging distances accurately.</li>
												</ul>
											</div>

											<p className="nse-paragraph mb-0 mt-30">Please note that the specific sensory motor tests conducted by RightEye may vary based on their assessment goals and the requirements of the individual or organization using their technology. If you are considering using RightEye for a specific purpose, I recommend contacting RightEye directly to get detailed information on the assessments and tests they offer, as well as any updates or new features that may have been introduced since my last update.</p>
										</div>
									</div>
								</div>
								<div className="col-lg-4">
									<aside className="sticky-top pb-1">
										<div className="widget">
											<ul className="service-menu">
												<li ><Link to="/biomicroscopy-tests"><span>Bio Microscopy</span><i className="fa fa-angle-right"></i></Link></li>
												<li><Link to="/cornealtopography-tests"><span>Corneal Topography</span><i className="fa fa-angle-right"></i></Link></li>
												<li><Link to="/dryeye-tests"><span>Dry Eye Test</span><i className="fa fa-angle-right"></i></Link></li>
												<li><Link to="/fundoscopy-tests"><span>Fundoscopy</span><i className="fa fa-angle-right"></i></Link></li>
												<li><Link to="/keratometry-tests"><span>Keratometry</span><i className="fa fa-angle-right"></i></Link></li>
												<li><Link to="/meibomianglandassessment-tests"><span>Meibomian gland Assessment</span><i className="fa fa-angle-right"></i></Link></li>
												<li><Link to="/ocularbiometry-tests"><span>Ocular Biometry</span><i className="fa fa-angle-right"></i></Link></li>
												<li><Link to="/opticalcoherencetomography-tests"><span>Optical Coherence Tomography (OCT)</span><i className="fa fa-angle-right"></i></Link></li>
												<li><Link to="/pachymetry-tests"><span>Pachymetry</span><i className="fa fa-angle-right"></i></Link></li>
												<li><Link to="/perimetry-tests"><span>Perimetry</span><i className="fa fa-angle-right"></i></Link></li>
												<li><Link to="/refraction-tests"><span>Refraction</span><i className="fa fa-angle-right"></i></Link></li>
												<li className="active"><Link to="/sensorymotor-tests"><span>Sensory Motor Test</span><i className="fa fa-angle-right"></i></Link></li>
												<li><Link to="/synoptophore-tests"><span>Synoptophore test</span><i className="fa fa-angle-right"></i></Link></li>
												<li><Link to="/tonometry-tests"><span>Tonometry</span><i className="fa fa-angle-right"></i></Link></li>
											</ul>
										</div>
										<Downloadables/>
										
									</aside>
								</div>
							</div>
						</div>
					</section>
					
				</div>
				
			</>
		);
	}
}

export default SensoryMotorTestDetail;