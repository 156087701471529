import React, {Component} from 'react';
import {Link} from 'react-router-dom';
import {Accordion} from 'react-bootstrap';

// Import Images
import bnrImg1 from "../../../images/banner/img1.jpg";
import waveBlue from "../../../images/shap/wave-blue.png";
import circleDots from "../../../images/shap/circle-dots.png";
import plusBlue from "../../../images/shap/plus-blue.png";
import servicesPic1 from "../../../images/noimage.jpg";
import pdf from "../../../images/icon/pdf.png";
import doc from "../../../images/icon/doc.png";
import Downloadables from '../../elements/downloadables';

class FundoscopyDetail extends Component{
	
	render(){
		return (
			<>
				
				<div className="page-content bg-white">
					
					<div className="banner-wraper">
						<div className="page-banner" style={{backgroundImage: "url("+bnrImg1+")"}}>
							<div className="container">
								<div className="page-banner-entry text-center">
									<h1>Eye Tests</h1>
									<nav aria-label="breadcrumb" className="breadcrumb-row">
										<ul className="breadcrumb">
											<li className="breadcrumb-item"><Link to="/"><svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-home"><path d="M3 9l9-7 9 7v11a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2z"></path><polyline points="9 22 9 12 15 12 15 22"></polyline></svg> Home</Link></li>
											<li className="breadcrumb-item"><Link to="/">Eye Tests</Link></li>
											<li className="breadcrumb-item active" aria-current="page">Fundoscopy</li>
										</ul>
									</nav>
								</div>
							</div>
							<img className="pt-img1 animate-wave" src={waveBlue} alt=""/>
							<img className="pt-img2 animate2" src={circleDots} alt=""/>
							<img className="pt-img3 animate-rotate" src={plusBlue} alt=""/>
						</div>
					</div>
					
					<section className="section-area section-sp1">
						<div className="container">
							<div className="row">
								<div className="col-lg-8 mb-30">
									{/*
									<div className="ttr-media mb-30">
										<img src={servicesPic1} className="rounded" alt=""/>
									</div>
									*/}
									<div className="clearfix">
										<div className="head-text mb-30">
											<h2 className="title mb-15">Fundoscopy</h2>
											<p className="nse-paragraph mb-0">Fundus Imaging Scan, is a valuable diagnostic procedure that we at Nakpil Eye Clinic to examine the back of the eye, including the retina, optic nerve, and blood vessels. Regular fundoscopy tests offer several benefits and serve various important purposes.</p>
											<h4 className="title mb-10 mt-20">Key uses and benefits of a slit lamp bio-microscope:</h4>
										</div>										
									</div>
									<div className="clearfix">
										<div className="head-text mb-30">
											<h4 className="title mb-10">Detection of Eye Diseases</h4>
											<p className="nse-paragraph mb-0">Fundoscopy allows for the early detection of eye diseases and conditions that can affect the retina and the optic nerve, such as diabetic retinopathy, age-related macular degeneration, glaucoma, and hypertensive retinopathy. Early detection is critical for timely treatment and to prevent vision los.</p>
											
											<h4 className="title mb-10 mt-10">Monitoring Eye Health</h4>
											<p className="nse-paragraph mb-0">Regular fundoscopy tests can be used to monitor changes in the retina and optic nerve over time. This is particularly important for individuals with pre-existing eye conditions or those at risk for developing them, such as people with diabetes.</p>

											<h4 className="title mb-10 mt-10">Assessment of Systemic Health</h4>
											<p className="nse-paragraph mb-0">The retina can offer insights into a person's overall health. Fundoscopy can help identify signs of systemic conditions like hypertension, atherosclerosis, and certain neurological disorders. For example, it can reveal signs of hypertensive retinopathy, characterized by changes in the blood vessels of the retina due to high blood pressure.</p>

											<h4 className="title mb-10 mt-10">Preoperative Evaluation</h4>
											<p className="nse-paragraph mb-0">Fundoscopy is often performed as part of the preoperative evaluation for cataract surgery or other eye surgeries to assess the condition of the retina and optic nerve.</p>

											<h4 className="title mb-10 mt-10">Prescription Adjustments</h4>
											<p className="nse-paragraph mb-0">Fundoscopy can also provide information that influences prescription adjustments for glasses or contact lenses, as it helps eye care professionals understand how certain eye conditions may affect a patient's vision.</p>

											<h4 className="title mb-10 mt-10">Documentation and Record-Keeping</h4>
											<p className="nse-paragraph mb-0">Fundoscopy images are often documented and stored as part of a patient's medical record. These records can serve as valuable references for future comparisons and assessments.</p>

											<h4 className="title mb-10 mt-10">Education and Patient Communication</h4>
											<p className="nse-paragraph mb-0">Fundoscopy provides an opportunity for eye care professionals to educate patients about the health of their eyes and discuss any findings, potentially leading to improved patient compliance with treatment and lifestyle recommendations.</p>

											<h4 className="title mb-10 mt-10">Research and Teaching</h4>
											<p className="nse-paragraph mb-0">Fundoscopy is used in research and medical education to help students and practitioners learn about the anatomy and pathology of the eye.</p>

											<h4 className="title mb-10 mt-10">Screening for Asymptomatic Patients</h4>
											<p className="nse-paragraph mb-0">In some cases, patients may have underlying eye conditions that do not exhibit noticeable symptoms. Regular fundoscopy can help identify these conditions in asymptomatic individuals.</p>
											
											<p className="nse-paragraph mb-0 mt-30">The benefits of regular fundoscopy tests are significant, as they contribute to the early detection and management of eye and systemic conditions. They are particularly important for individuals with specific risk factors, such as diabetes or a family history of eye diseases. It's essential for individuals to follow their eye care professional's recommendations regarding the frequency of fundoscopy examinations, as the ideal schedule can vary based on individual risk factors and medical history.</p>
										</div>
									</div>
								</div>
								<div className="col-lg-4">
									<aside className="sticky-top pb-1">
										<div className="widget">
											<ul className="service-menu">
												<li><Link to="/biomicroscopy-tests"><span>Bio Microscopy</span><i className="fa fa-angle-right"></i></Link></li>
												<li><Link to="/cornealtopography-tests"><span>Corneal Topography</span><i className="fa fa-angle-right"></i></Link></li>
												<li><Link to="/dryeye-tests"><span>Dry Eye Test</span><i className="fa fa-angle-right"></i></Link></li>
												<li className="active"><Link to="/fundoscopy-tests"><span>Fundoscopy</span><i className="fa fa-angle-right"></i></Link></li>
												<li><Link to="/keratometry-tests"><span>Keratometry</span><i className="fa fa-angle-right"></i></Link></li>
												<li><Link to="/meibomianglandassessment-tests"><span>Meibomian gland Assessment</span><i className="fa fa-angle-right"></i></Link></li>
												<li><Link to="/ocularbiometry-tests"><span>Ocular Biometry</span><i className="fa fa-angle-right"></i></Link></li>
												<li><Link to="/opticalcoherencetomography-tests"><span>Optical Coherence Tomography (OCT)</span><i className="fa fa-angle-right"></i></Link></li>
												<li><Link to="/pachymetry-tests"><span>Pachymetry</span><i className="fa fa-angle-right"></i></Link></li>
												<li><Link to="/perimetry-tests"><span>Perimetry</span><i className="fa fa-angle-right"></i></Link></li>
												<li><Link to="/refraction-tests"><span>Refraction</span><i className="fa fa-angle-right"></i></Link></li>
												<li><Link to="/sensorymotor-tests"><span>Sensory Motor Test</span><i className="fa fa-angle-right"></i></Link></li>
												<li><Link to="/synoptophore-tests"><span>Synoptophore test</span><i className="fa fa-angle-right"></i></Link></li>
												<li><Link to="/tonometry-tests"><span>Tonometry</span><i className="fa fa-angle-right"></i></Link></li>
											</ul>
										</div>
										<Downloadables/>
										
									</aside>
								</div>
							</div>
						</div>
					</section>
					
				</div>
				
			</>
		);
	}
}

export default FundoscopyDetail;