import React, { Component } from 'react';
import {Link} from 'react-router-dom';
import pdf from "../../images/icon/pdf.png";
import doc from "../../images/icon/doc.png";

class Downloadables extends Component{
	render(){
		
		return(
			<>
				
				<div className="widget">
					<div className="brochure-bx">
						<h5 className="title-head">Download</h5>
						<Link to="#" className="download-link">
							<img src={pdf} alt=""/>
							<h5 className="title">Download our Brochures</h5>
							<span>Download</span>
						</Link>
						<Link to="#" className="download-link">
							<img src={doc} alt=""/>
							<h5 className="title">Our Company Details</h5>
							<span>Download</span>
						</Link>
					</div>
				</div>
				
			</>
		);
	}
}

export default Downloadables;