import React, {Component} from 'react';
import {Link} from 'react-router-dom';
import {Accordion} from 'react-bootstrap';

// Import Images
import bnrImg1 from "../../../images/banner/img1.jpg";
import waveBlue from "../../../images/shap/wave-blue.png";
import circleDots from "../../../images/shap/circle-dots.png";
import plusBlue from "../../../images/shap/plus-blue.png";
import servicesPic1 from "../../../images/services/perimetry.jpg";
import Downloadables from '../../elements/downloadables';

class Perimetry extends Component{
	
	render(){
		return (
			<>
				
				<div className="page-content bg-white">
					
					<div className="banner-wraper">
						<div className="page-banner" style={{backgroundImage: "url("+bnrImg1+")"}}>
							<div className="container">
								<div className="page-banner-entry text-center">
									<h1>Services</h1>
									<nav aria-label="breadcrumb" className="breadcrumb-row">
										<ul className="breadcrumb">
											<li className="breadcrumb-item"><Link to="/"><svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-home"><path d="M3 9l9-7 9 7v11a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2z"></path><polyline points="9 22 9 12 15 12 15 22"></polyline></svg> Home</Link></li>
											<li className="breadcrumb-item"><Link to="/services">Services</Link></li>
											<li className="breadcrumb-item active" aria-current="page">Perimetry or Visual Field Test</li>
										</ul>
									</nav>
								</div>
							</div>
							<img className="pt-img1 animate-wave" src={waveBlue} alt=""/>
							<img className="pt-img2 animate2" src={circleDots} alt=""/>
							<img className="pt-img3 animate-rotate" src={plusBlue} alt=""/>
						</div>
					</div>
					
					<section className="section-area section-sp1">
						<div className="container">
							<div className="row">
								<div className="col-lg-8 mb-30">
									<div className="ttr-media mb-30">
										<img src={servicesPic1} className="rounded" alt=""/>
									</div>
									<div className="clearfix">
										<div className="head-text mb-30">
											<h2 className="title mb-15">Perimetry or Visual Field Test</h2>
											<p className="nse-paragraph mb-30">Welcome to Nakpil Eye Clinic, where we prioritize your visual health and clarity. Explore the intricacies of your visual field with our Perimetry test, also commonly referred to as the Visual Field Test. This advanced diagnostic tool offers invaluable insights into your field of vision, aiding in the early detection and management of various eye conditions.</p>																						
											<p className="nse-paragraph mb-0">Perimetry, or the Visual Field Test, is a sophisticated diagnostic procedure that assesses your field of vision, providing a comprehensive map of how you perceive and interpret visual information in your surroundings. This test is instrumental in detecting abnormalities, enabling our expert eye care professionals to create tailored solutions for your visual well-being.</p>			
										</div>										
									</div>
									<div className="clearfix">
										<div className="head-text mb-30">
											<div className='heading-bx mt-5 mb-5'>
												<h6 className="title-ext text-primary">Key Aspects of Perimetry</h6>
											</div>										
											<h6 className="text-info mb-10 mt-30" >1. Early Detection of Eye Conditions</h6>
											<p className="nse-paragraph mb-10">Perimetry is a vital tool for identifying early signs of eye conditions such as glaucoma, retinal diseases, and neurological disorders, allowing for prompt intervention and management.</p>

											<h6 className="text-info mb-10 mt-30">2. Mapping Visual Sensitivity</h6>
											<p className="nse-paragraph mb-10">The procedure maps your visual sensitivity across different areas of your visual field, helping us understand variations in your ability to detect light stimuli.</p>

											
											<h6 className="text-info mb-10 mt-30">3. Customized Treatment Plans</h6>
											<p className="nse-paragraph mb-10">The insights gained from Perimetry enable us to develop personalized treatment plans based on the specific characteristics of your visual field, ensuring targeted and effective interventions.</p>

											
											<h6 className="text-info mb-10 mt-30">4. Monitoring Progression</h6>
											<p className="nse-paragraph mb-10">For individuals with known eye conditions, regular Perimetry tests help monitor the progression of the disease and adjust treatment strategies accordingly.</p>

											<h4 className="title mb-10 mt-30">Why Choose Nakpil Eye Clinic for Perimetry?</h4>			
											<div className="ml-30">	
												<ul>
													<li className='nse-list-group-item'>Expert Eye Care Professionals: Our team consists of a skilled ophthalmologist, optometrists, and technicians with expertise in utilizing Perimetry for accurate diagnostics and personalized care.</li>
													<li className='nse-list-group-item'>Cutting-Edge Technology: Nakpil Eye Clinic invests in the latest Perimetry technology, ensuring precise and detailed assessments of your visual field.</li>
													<li className='nse-list-group-item'>Patient-Centric Approach: We understand the importance of your comfort and understanding during the Perimetry procedure, providing a supportive and informative environment.</li>
													<li className='nse-list-group-item'>Holistic Eye Care: Beyond Perimetry, our clinic offers a comprehensive range of eye care services to address all aspects of your visual health.</li>
													
												</ul>
											</div>

											

											<p className="nse-paragraph mb-0 mt-30">Invest in Your Visual Insight. Embark on a journey to uncover the intricacies of your visual field with Perimetry at Nakpil Eye Clinic. Let us be your partners in preserving and enhancing your visual well-being.</p>
											<p className="nse-paragraph mb-0 mt-10">Discover the full spectrum of your vision at Nakpil Eye Clinic – Your Eye and Vision Specialist.</p>

											
										</div>
										{
											/*
											<Accordion defaultActiveKey="0" className="accordion ttr-accordion1">
											<Accordion.Item eventKey="0">
												<Accordion.Header>Question 1</Accordion.Header>
												<Accordion.Body>
													<p className="mb-0">Answer 1</p>
												</Accordion.Body>
											</Accordion.Item>
											<Accordion.Item eventKey="1">
												<Accordion.Header>Question 2</Accordion.Header>
												<Accordion.Body>
													<p className="mb-0">Answer 2</p>
												</Accordion.Body>
											</Accordion.Item>
										</Accordion>
											*/
										}
										
									</div>
								</div>
								<div className="col-lg-4">
									<aside className="sticky-top pb-1">
										<div className="widget">										
											<ul className="service-menu">
												<div className='heading-bx'>
													<h6 className="title-ext text-primary">Examination Packages</h6>
												</div>
												<li><Link to="/standardeyeexam"><span>Standard Eye Examination</span><i className="fa fa-angle-right"></i></Link></li>
												<li><Link to="/premiumeyeexam"><span>Premium Eye Examination</span><i className="fa fa-angle-right"></i></Link></li>
												<li><Link to="/pediatriceyeexam"><span>Pediatric Eye Examination</span><i className="fa fa-angle-right"></i></Link></li>
												<li><Link to="/executiveeyeexam"><span>Executive Eye Examination</span><i className="fa fa-angle-right"></i></Link></li>
												<li><Link to="/executiveeyeplusexam"><span>Executive Plus Eye Examination</span><i className="fa fa-angle-right"></i></Link></li>
												<li><Link to="/corporateeyeexam"><span>Corporate Eye Care Program</span><i className="fa fa-angle-right"></i></Link></li>
												<li><Link to="/followupeyeexam"><span>Follow-up Eye Examination</span><i className="fa fa-angle-right"></i></Link></li>
											</ul>
										</div>
										<div className="widget">
											<ul className="service-menu">
												<div className='heading-bx'>
													<h6 className="title-ext text-primary">Other Services</h6>
												</div>
												<li><Link to="/biometry"><span>Biometry</span><i className="fa fa-angle-right"></i></Link></li>
												<li><Link to="/cycloplegicrefraction"><span>Cycloplegic Eyedrops</span><i className="fa fa-angle-right"></i></Link></li>
												<li><Link to="/foreignbdr"><span>Foreign Bodies Removal</span><i className="fa fa-angle-right"></i></Link></li>
												<li><Link to="/fundusairdoc"><span>Fundus Scan with AirDoc</span><i className="fa fa-angle-right"></i></Link></li>
												<li><Link to="/funduskanghua"><span>Fundus Scan with Kanghua</span><i className="fa fa-angle-right"></i></Link></li>
												<li><Link to="/iopassessment"><span>Intra Ocular Pressure Assessment</span><i className="fa fa-angle-right"></i></Link></li>
												<li><Link to="/ishiharatest"><span>Ishihara Color Vision Test</span><i className="fa fa-angle-right"></i></Link></li>
												<li><Link to="/ishiharafanswort"><span>Ishihara with Farnsworth</span><i className="fa fa-angle-right"></i></Link></li>
												<li><Link to="/orthokeratology"><span>OrthoKeratology Lens</span><i className="fa fa-angle-right"></i></Link></li>
												<li><Link to="/orthokeratologylens"><span>OrthoKeratology Lens Fitting</span><i className="fa fa-angle-right"></i></Link></li>
												<li className="active"><Link to="/perimetry"><span>Perimetry / Visual Field Test</span><i className="fa fa-angle-right"></i></Link></li>
												<li><Link to="/topography"><span>Topography</span><i className="fa fa-angle-right"></i></Link></li>
												<li><Link to="/visiontherapy"><span>Vision Therapy</span><i className="fa fa-angle-right"></i></Link></li>
												<li><Link to="/visualpa"><span>Visual Performance Assessment</span><i className="fa fa-angle-right"></i></Link></li>
											</ul>
										</div>
										<Downloadables/>
										
									</aside>
								</div>
							</div>
						</div>
					</section>
					
				</div>
				
			</>
		);
	}
}

export default Perimetry;