import React, {Component} from 'react';
import {Link} from 'react-router-dom';
import {Accordion} from 'react-bootstrap';

// Import Images
import bnrImg1 from "../../../images/banner/img1.jpg";
import waveBlue from "../../../images/shap/wave-blue.png";
import circleDots from "../../../images/shap/circle-dots.png";
import plusBlue from "../../../images/shap/plus-blue.png";
import servicesPic1 from "../../../images/noimage.jpg";
import Downloadables from '../../elements/downloadables';

class PremiumExam extends Component{
	
	render(){
		return (
			<>
				
				<div className="page-content bg-white">
					
					<div className="banner-wraper">
						<div className="page-banner" style={{backgroundImage: "url("+bnrImg1+")"}}>
							<div className="container">
								<div className="page-banner-entry text-center">
									<h1>Services</h1>
									<nav aria-label="breadcrumb" className="breadcrumb-row">
										<ul className="breadcrumb">
											<li className="breadcrumb-item"><Link to="/"><svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-home"><path d="M3 9l9-7 9 7v11a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2z"></path><polyline points="9 22 9 12 15 12 15 22"></polyline></svg> Home</Link></li>
											<li className="breadcrumb-item"><Link to="/services">Services</Link></li>
											<li className="breadcrumb-item active" aria-current="page">Premium Eye Examination</li>
										</ul>
									</nav>
								</div>
							</div>
							<img className="pt-img1 animate-wave" src={waveBlue} alt=""/>
							<img className="pt-img2 animate2" src={circleDots} alt=""/>
							<img className="pt-img3 animate-rotate" src={plusBlue} alt=""/>
						</div>
					</div>
					
					<section className="section-area section-sp1">
						<div className="container">
							<div className="row">
								<div className="col-lg-8 mb-30">
									{
										/*
									<div className="ttr-media mb-30">
										<img src={servicesPic1} className="rounded" alt=""/>
									</div>
										*/
									}
									<div className="clearfix">
										<div className="head-text mb-30">
											<h2 className="title mb-15">Premium Eye Examination</h2>
											<p className="nse-paragraph mb-0">Our Premium Eye Examination Package is a pinnacle offering, meticulously crafted to provide an unparalleled assessment of your eye health. Elevate your eye care experience with this all-inclusive package, ensuring a thorough understanding of your visual well-being.</p>											
										</div>										
									</div>
									<div className="clearfix">
										<div className="head-text mb-30">
											<h4 className="title mb-10 mt-10">Inclusions of the Standard Eye Examination Package</h4>											
											<h6 className="text-info mb-10 mt-30" >1. Blood Pressure Test</h6>
											<p className="nse-paragraph mb-10">Lay the foundation with a thorough assessment of your general health through a blood pressure test, recognizing the vital link between systemic health and ocular well-being.</p>

											<h6 className="text-info mb-10 mt-30">2. Visual Acuity Assessment</h6>
											<p className="nse-paragraph mb-10">Precisely measure the sharpness and clarity of your vision, identifying and addressing any refractive errors impacting your visual acuity.</p>

											
											<h6 className="text-info mb-10 mt-30">3. Eye Motility Assessment</h6>
											<p className="nse-paragraph mb-10">Evaluate the coordination and movement of your eye muscles, ensuring optimal functionality and identifying potential issues.</p>

											
											<h6 className="text-info mb-10 mt-30">4. Basic Color Vision Assessment</h6>
											<p className="nse-paragraph mb-10">Explore your ability to perceive and differentiate colors accurately, assessing the health of your color vision.</p>

											
											<h6 className="text-info mb-10 mt-30">5. Physical Eye Examination (Bio-microscopy / Ophthalmoscopy)</h6>
											<p className="nse-paragraph mb-10">Dive into the intricate details of your eye's structures through advanced tools, allowing for a comprehensive physical examination.</p>

											
											<h6 className="text-info mb-10 mt-30">6. Comprehensive Dry Eye Assessment</h6>
											<p className="nse-paragraph mb-10">Identify and address symptoms of dry eye syndrome at an advanced level, ensuring your eyes remain comfortable and well-lubricated.</p>

											
											<h6 className="text-info mb-10 mt-30">7. Cataract Screening</h6>
											<p className="nse-paragraph mb-10">Screen for early signs of cataracts, facilitating timely intervention and preserving your visual clarity.</p>

											
											<h6 className="text-info mb-10 mt-30">8. Glaucoma Screening VH Test with Tonometry IOP Test</h6>
											<p className="nse-paragraph mb-10">Detects potential indicators of glaucoma through an in-depth screening, including Tonometry IOP testing for precise intraocular pressure measurement.</p>

											
											<h6 className="text-info mb-10 mt-30">9. Vision Examination & Correction (Objective / Subjective)</h6>
											<p className="nse-paragraph mb-10">Tailor your vision correction needs through both objective and subjective assessments, ensuring personalized and accurate prescriptions.</p>

											<h6 className="text-info mb-10 mt-30">10. Automated Keratometry (As Necessary)</h6>
											<p className="nse-paragraph mb-10">Employ advanced keratometry, as necessary, to assess the curvature of the cornea and gather additional data for comprehensive diagnostics.</p>
											

											<p className="nse-paragraph mb-0 mt-30">Immerse yourself in the epitome of eye care with the Premium Eye Examination Package at Nakpil Eye Clinic. Let us guide you towards excellence, ensuring your eyes receive the highest standard of care.</p>

											<h4 className="text-warning mt-30">Php 1,499.00</h4>
											<div >	
											<p className="mb-0">Note: Price is subject to change without prior notice.</p>
											</div>
										</div>
										{
											/*
											<Accordion defaultActiveKey="0" className="accordion ttr-accordion1">
											<Accordion.Item eventKey="0">
												<Accordion.Header>Question 1</Accordion.Header>
												<Accordion.Body>
													<p className="mb-0">Answer 1</p>
												</Accordion.Body>
											</Accordion.Item>
											<Accordion.Item eventKey="1">
												<Accordion.Header>Question 2</Accordion.Header>
												<Accordion.Body>
													<p className="mb-0">Answer 2</p>
												</Accordion.Body>
											</Accordion.Item>
										</Accordion>
											*/
										}
										
									</div>
								</div>
								<div className="col-lg-4">
									<aside className="sticky-top pb-1">
										<div className="widget">										
											<ul className="service-menu">
												<div className='heading-bx'>
													<h6 className="title-ext text-primary">Examination Packages</h6>
												</div>
												<li><Link to="/standardeyeexam"><span>Standard Eye Examination</span><i className="fa fa-angle-right"></i></Link></li>
												<li className="active"><Link to="/premiumeyeexam"><span>Premium Eye Examination</span><i className="fa fa-angle-right"></i></Link></li>
												<li><Link to="/pediatriceyeexam"><span>Pediatric Eye Examination</span><i className="fa fa-angle-right"></i></Link></li>
												<li><Link to="/executiveeyeexam"><span>Executive Eye Examination</span><i className="fa fa-angle-right"></i></Link></li>
												<li><Link to="/executiveeyeplusexam"><span>Executive Plus Eye Examination</span><i className="fa fa-angle-right"></i></Link></li>
												<li><Link to="/corporateeyeexam"><span>Corporate Eye Care Program</span><i className="fa fa-angle-right"></i></Link></li>
												<li><Link to="/followupeyeexam"><span>Follow-up Eye Examination</span><i className="fa fa-angle-right"></i></Link></li>
											</ul>
										</div>
										<div className="widget">
											<ul className="service-menu">
												<div className='heading-bx'>
													<h6 className="title-ext text-primary">Other Services</h6>
												</div>
												<li><Link to="/biometry"><span>Biometry</span><i className="fa fa-angle-right"></i></Link></li>
												<li><Link to="/cycloplegicrefraction"><span>Cycloplegic Eyedrops</span><i className="fa fa-angle-right"></i></Link></li>
												<li><Link to="/foreignbdr"><span>Foreign Bodies Removal</span><i className="fa fa-angle-right"></i></Link></li>
												<li><Link to="/fundusairdoc"><span>Fundus Scan with AirDoc</span><i className="fa fa-angle-right"></i></Link></li>
												<li><Link to="/funduskanghua"><span>Fundus Scan with Kanghua</span><i className="fa fa-angle-right"></i></Link></li>
												<li><Link to="/iopassessment"><span>Intra Ocular Pressure Assessment</span><i className="fa fa-angle-right"></i></Link></li>
												<li><Link to="/ishiharatest"><span>Ishihara Color Vision Test</span><i className="fa fa-angle-right"></i></Link></li>
												<li><Link to="/ishiharafanswort"><span>Ishihara with Farnsworth</span><i className="fa fa-angle-right"></i></Link></li>
												<li><Link to="/orthokeratology"><span>OrthoKeratology Lens</span><i className="fa fa-angle-right"></i></Link></li>
												<li><Link to="/orthokeratologylens"><span>OrthoKeratology Lens Fitting</span><i className="fa fa-angle-right"></i></Link></li>
												<li><Link to="/perimetry"><span>Perimetry / Visual Field Test</span><i className="fa fa-angle-right"></i></Link></li>
												<li><Link to="/topography"><span>Topography</span><i className="fa fa-angle-right"></i></Link></li>
												<li><Link to="/visiontherapy"><span>Vision Therapy</span><i className="fa fa-angle-right"></i></Link></li>
												<li><Link to="/visualpa"><span>Visual Performance Assessment</span><i className="fa fa-angle-right"></i></Link></li>
											</ul>
										</div>
										<Downloadables/>
										
									</aside>
								</div>
							</div>
						</div>
					</section>
					
				</div>
				
			</>
		);
	}
}

export default PremiumExam;